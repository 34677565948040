import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { BookingItemOnRoute as BookingItemOnRouteType } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { IconBasedOnSalesItem, NoddiButton, NoddiChip, NoddiDialog, getCarDisplayName } from 'noddi-ui';
import { differenceBetweenDates } from 'noddi-util';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { UnableToCompleteCarModal } from '../../components/ui/BookingServiceCard/UnableToCompleteCar';
import AddExtraServiceToBookingItem from './AddExtraServiceToBookingItem';
import CarSpecifications from './CarSpecifications';
import MeasureTiresAndCompleteBookingItem from './CompleteBookingItem';
import MeasureTires from './CompleteBookingItem/MeasureTires';

interface BookingItemOnRouteProps {
  bookingItem: BookingItemOnRouteType;
  routeItemId: number;
}

const BookingItemOnRoute = ({ bookingItem, routeItemId }: BookingItemOnRouteProps) => {
  const { routeId } = useParams();
  const { noddiToast } = useNoddiToast();

  const carName = getCarDisplayName(bookingItem.car);
  const [isCarSetAsFinished, setIsCarSetAsFinished] = useState(false);
  const [editCarAfterFinished, setEditCarAfterFinished] = useState(false);
  const [unableToCompleteModal, setUnableToCompleteModal] = useState(false);
  const [addExtraSalesItemsModalOpen, setAddExtraSalesItemsModalOpen] = useState(false);
  const [measureTiresModalOpen, setMeasureTiresModalOpen] = useState(false);
  const { orderLines } = bookingItem;

  const bookingItemIsFinished = bookingItem.completedAt || bookingItem.isAllOrderLinesUnableToComplete;
  const showEditCarAfterFinished = !editCarAfterFinished && bookingItemIsFinished;

  const salesItemsOnBookingItem = bookingItem.orderLines.map((item) => ({
    id: item.salesItemId,
    name: item.description
  }));

  const { mutateAsync: markBookingItemAsComplete, isPending: isCompleteBookingItemLoading } = noddiAsync.usePost({
    type: URLKeys.postMarkBookingItemsAsComplete,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: {
            routeItemId
          }
        });
        noddiToast.success(t`Car finished`);
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const hasMeasuredThisWeek = bookingItem.car?.latestMeasuredAt
    ? Math.abs(differenceBetweenDates(bookingItem.car.latestMeasuredAt, new Date(), 'days')) < 7
    : false;

  return (
    <div className='py-6 '>
      <p className='text-5 font-semibold sm:mb-5'>{carName}</p>
      {showEditCarAfterFinished ? (
        <div className='mt-4 flex justify-between'>
          <NoddiChip
            variant={bookingItem.isAllOrderLinesUnableToComplete ? 'destructive' : 'success'}
            label={bookingItem.isAllOrderLinesUnableToComplete ? t`Unable to complete` : t`Finished`}
            icon={bookingItem.isAllOrderLinesUnableToComplete ? 'Cross' : 'Check'}
          />
          <NoddiButton variant='ghost' startIcon='Edit' className='ml-4' onClick={() => setEditCarAfterFinished(true)}>
            <Trans>Edit</Trans>
          </NoddiButton>
        </div>
      ) : (
        <>
          {bookingItem.car?.generation && (
            <div>
              <p className='mb-1 font-bold'>
                <Trans>Car specifications</Trans>
              </p>
              <CarSpecifications carGeneration={bookingItem.car.generation} />
            </div>
          )}
          <NoddiButton onClick={() => setMeasureTiresModalOpen(true)} variant='link' startIcon='Plus' className='pl-0'>
            <Trans>Add wheel measurements</Trans>
          </NoddiButton>

          <div className='flex items-center justify-between'>
            <p className='mb-2 mt-4 font-bold'>
              <Trans>My tasks</Trans>
            </p>
            <NoddiButton
              variant='link'
              startIcon='Plus'
              className='mt-2'
              onClick={() => setAddExtraSalesItemsModalOpen(true)}
            >
              <Trans>Add task</Trans>
            </NoddiButton>
          </div>
          {orderLines.map(({ id, description, salesItemBookingCategory }) => (
            <div key={id} className='mt-2  flex items-center gap-2 rounded-lg border bg-systemColors-purpleBg p-4'>
              <IconBasedOnSalesItem categorySlug={salesItemBookingCategory.slug} />
              <p>{description}</p>
            </div>
          ))}

          <div className='mt-4 flex items-center gap-4'>
            <NoddiButton fullWidth variant='secondary' onClick={() => setUnableToCompleteModal(true)}>
              <Trans>Unable to complete</Trans>
            </NoddiButton>
            <NoddiButton
              fullWidth
              loading={isCompleteBookingItemLoading}
              onClick={async () => {
                // if the car wheels has been measured, mark it as complete
                if (hasMeasuredThisWeek) {
                  await markBookingItemAsComplete({ bookingItemId: bookingItem.id });
                } else {
                  setIsCarSetAsFinished(true);
                }
              }}
            >
              <Trans>Finish car</Trans>
            </NoddiButton>
          </div>
        </>
      )}
      {isCarSetAsFinished && (
        <NoddiDialog title={t`Almost there`} onClose={() => setIsCarSetAsFinished(false)} open={isCarSetAsFinished}>
          <MeasureTiresAndCompleteBookingItem
            carId={bookingItem.car.id}
            routeItemId={routeItemId}
            bookingItemId={bookingItem.id}
          />
        </NoddiDialog>
      )}
      {measureTiresModalOpen && (
        <NoddiDialog
          title={t`Measure tires`}
          onClose={() => setMeasureTiresModalOpen(false)}
          open={measureTiresModalOpen}
        >
          <MeasureTires carId={bookingItem.car.id} routeItemId={routeItemId} bookingItemId={bookingItem.id} />
        </NoddiDialog>
      )}
      <UnableToCompleteCarModal
        setUnableToCompleteModal={setUnableToCompleteModal}
        unableToCompleteModal={unableToCompleteModal}
        routeId={routeId as string}
        routeItemId={routeItemId.toString()}
        bookingId={bookingItem.id}
        salesItems={salesItemsOnBookingItem}
      />
      <NoddiDialog
        title={t`Add extra task`}
        open={addExtraSalesItemsModalOpen}
        onClose={() => setAddExtraSalesItemsModalOpen(false)}
      >
        <AddExtraServiceToBookingItem
          bookingItemId={bookingItem.id}
          routeItemId={routeItemId}
          salesItemsOnBookingItem={salesItemsOnBookingItem}
        />
      </NoddiDialog>
    </div>
  );
};

export default BookingItemOnRoute;
