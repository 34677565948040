import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ApiErrorMessage, LoadingScreen } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

interface RoutePaymentDetailsProps {
  routeId: number;
}

const RoutePaymentDetails = ({ routeId }: RoutePaymentDetailsProps) => {
  const {
    data: paymentDetails,
    isPending: isPaymentDetailsPending,
    error: paymentDetailsError
  } = noddiAsync.useGet({
    type: URLKeys.getEstimatedRoutePayment,
    input: { routeId }
  });

  if (paymentDetailsError) {
    return <ApiErrorMessage error={paymentDetailsError} />;
  }

  if (isPaymentDetailsPending) {
    return <LoadingScreen />;
  }

  const mileageCompensation = formatCurrencyAmount(paymentDetails.paymentPerKilometer);

  return (
    <div className='flex flex-col gap-4 text-primary-darkPurple'>
      <div className='flex flex-col items-center rounded-lg bg-secondary-coral p-5'>
        <p className='text-center'>
          <Trans>Estimated payment for this route</Trans>
        </p>
        <p className='font-bold'>{formatCurrencyAmount(paymentDetails.estimatedPayment, 0)}</p>
      </div>
      <div className='flex flex-col items-center rounded-lg bg-systemColors-purpleBg p-5'>
        <p className='text-center'>
          <Trans>The minimum guaranteed is</Trans>
        </p>
        <p className='text-center font-bold'>
          {formatCurrencyAmount(paymentDetails.minimumPaymentPerHour, 0)} <Trans>per hour and</Trans>{' '}
          {formatCurrencyAmount(paymentDetails.baseMinimumPayment, 0)} <Trans>in total</Trans>
        </p>
      </div>
      <p className='my-4'>
        <Trans>
          You will also receive mileage compensation at a rate of {mileageCompensation} per km as well as road toll.
        </Trans>
      </p>
    </div>
  );
};

export default RoutePaymentDetails;
