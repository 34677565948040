import { Trans } from '@lingui/macro';
import { Divider, Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { DetailedRouteItem } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ErrorPage,
  NoddiAsyncError,
  NoddiButton,
  NoddiCollapseCard,
  NoddiIcon,
  colors,
  getLongAddressName
} from 'noddi-ui';
import { DateFormats, differenceBetweenDates, format, fromSecondsToMinutes } from 'noddi-util';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../appRoutes';
import { openAddressInGoogleMaps } from '../../utils/navigation';
import RouteMessageBox from './RouteMessageBox';

export function RouteCardForBooking({
  route,
  overallJobStartedAt,
  overAllJobId,
  estimatedArrivalPrevJob
}: {
  route: DetailedRouteItem;
  overallJobStartedAt: string;
  overAllJobId?: string;
  estimatedArrivalPrevJob?: string;
}) {
  const data = useParams<{ routeId: string }>();
  const navigate = useNavigate();
  const { noddiToast } = useNoddiToast();

  const routeId = data.routeId;
  if (!routeId) {
    return <ErrorPage />;
  }

  const { mutateAsync: markRouteItemStarted, isPending: isStartJobPending } = noddiAsync.usePost({
    type: URLKeys.postMarkRouteItemStarted,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getRouteServiceWorker, input: { routeId } });
        navigate(routes.routeItemDetail.getPath({ routeId: overAllJobId || '', routeItemId: route.id }));
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const estimatedDrivingTimeToAppointment = estimatedArrivalPrevJob
    ? Math.round(differenceBetweenDates(route.estimatedArrival, estimatedArrivalPrevJob, 'minutes'))
    : undefined;

  const color = route.completedAt ? '#81c784' : route.isCancelled ? colors.systemColors.grey : undefined;

  const textColor = route.completedAt ? colors.primary.black : undefined;
  const showTravelEstimation = estimatedDrivingTimeToAppointment && !route.completedAt;

  const customer = route.customer;

  return (
    <Stack mt={showTravelEstimation ? undefined : 2}>
      {showTravelEstimation ? (
        <Stack direction='row' alignItems='center' px={3} style={{ marginTop: 12, marginBottom: 12 }}>
          <NoddiIcon name='Car' size='medium' className='mb-1 mr-2' />
          <Typography color={textColor ?? 'text.secondary'} style={{ fontSize: 10 }}>
            <Trans>Estimated driving time to next job is</Trans> {estimatedDrivingTimeToAppointment}{' '}
            <Trans>minutes</Trans>
          </Typography>
        </Stack>
      ) : null}
      <NoddiCollapseCard
        borderColor={route.completedAt ? colors.signal.success : colors.primary.orange}
        key={route.id}
        sx={{
          backgroundColor: color
        }}
        preventHandleExpand
        header={
          <Stack
            alignItems='start'
            direction='column'
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <Stack alignItems='center' direction='row' justifyContent='space-between' style={{ width: '100%' }}>
              <Stack alignItems='start' direction='column' spacing={1}>
                {route.estimatedWaitingTime > 0 ? (
                  <Stack direction='row' alignItems='center'>
                    <NoddiIcon name='ClockCircle' className='mr-1' color={colors.signal.danger} />
                    <Typography variant='body2' color='error'>
                      {fromSecondsToMinutes(route.estimatedWaitingTime)} <Trans>minutes waiting time</Trans>
                    </Typography>
                  </Stack>
                ) : null}
                <Typography variant='body2' color={textColor}>
                  {format(route.estimatedServiceStart, DateFormats.TIME)}
                </Typography>
                <Stack alignItems='start' direction='row' spacing={1}>
                  <Typography color={textColor ?? 'text.secondary'} variant='body2'>
                    {route.cars.length} {route.cars.length > 1 ? 'cars' : 'car'}
                  </Typography>
                  <Typography color={textColor ?? 'text.secondary'} variant='body2'>
                    {fromSecondsToMinutes(route.expectedCompletionTimeInSeconds)} minutes
                  </Typography>
                </Stack>
              </Stack>
              {route.completedAt && (
                <Stack alignItems='center' direction='row'>
                  <Typography fontSize={14} style={{ marginRight: 4 }}>
                    <b>
                      <Trans>Completed</Trans>
                    </b>
                  </Typography>

                  <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1 mr-2' />
                </Stack>
              )}
            </Stack>
          </Stack>
        }
        collapseBody={
          <Stack direction='column' spacing={1}>
            <div>
              {route.communicatedArrivalStart && route.communicatedArrivalEnd && (
                <Typography variant='body2' style={{ marginBottom: 12 }}>
                  <Trans>
                    The customer expects you to arrive between{' '}
                    {format(route.communicatedArrivalStart, DateFormats.TIME)} and{' '}
                    {format(route.communicatedArrivalEnd, DateFormats.TIME)}
                  </Trans>
                </Typography>
              )}
            </div>
            {route.commentsAdmin && (
              <Typography variant='body2'>
                <b>
                  <Trans>Admin comment</Trans>
                </b>{' '}
                - {route.commentsAdmin}
              </Typography>
            )}
            {route.commentsUser && (
              <Typography variant='body2'>
                <b>
                  <Trans>User comment</Trans>
                </b>{' '}
                - {route.commentsUser}
              </Typography>
            )}
            <Typography style={{ marginTop: 20 }}>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <NoddiIcon name='User' className='mb-1 mr-2' /> {customer.userGroup.name}
              </span>
            </Typography>

            <Typography onClick={() => openAddressInGoogleMaps(route.address)}>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                <NoddiIcon name='LocationPin' className='mb-1 mr-2' />{' '}
                {route.address ? getLongAddressName(route.address) : undefined}
              </span>
            </Typography>

            <Typography
              onClick={() => window.open('tel:' + customer.phoneNumber)}
              style={route.completedAt ? undefined : { marginBottom: 24 }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                <NoddiIcon name='Phone' className='mb-1 mr-2' /> {customer.phoneNumber}
              </span>
            </Typography>

            {/* Shows all cars */}
            {route.cars.map(({ make, model, licensePlateNumber, salesItems }) => {
              return (
                <Stack direction='column' spacing={1} marginTop={4}>
                  <Typography>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <NoddiIcon name='Car' size='medium' className='mb-1 mr-2' /> {make} {model} - {licensePlateNumber}
                    </span>
                  </Typography>
                  {salesItems.map((item) => (
                    <Typography key={item.id} style={{ color: colors.primary.orange }}>
                      {item.name}
                    </Typography>
                  ))}
                </Stack>
              );
            })}

            <Divider />

            {overallJobStartedAt && !route?.isCancelled && (
              <>
                <RouteMessageBox
                  estimatedDrivingTimeToAppointment={estimatedDrivingTimeToAppointment}
                  route={route}
                  completed={!!route.completedAt}
                  overAllJobId={overAllJobId ?? ''}
                />

                {!route.startedAt && (
                  <NoddiButton
                    style={{ marginTop: '24px' }}
                    variant={route.completedAt ? 'ghost' : undefined}
                    loading={isStartJobPending}
                    onClick={async () => {
                      const req = {
                        routeId: parseInt(routeId),
                        routeItemId: route.id
                      };
                      await markRouteItemStarted(req);
                    }}
                  >
                    <Typography>
                      <Trans>Start</Trans>
                    </Typography>
                  </NoddiButton>
                )}
                {route.startedAt && (
                  <NoddiButton
                    style={{ marginTop: '24px' }}
                    variant={route.completedAt ? 'ghost' : undefined}
                    onClick={() => {
                      navigate(routes.routeItemDetail.getPath({ routeId: overAllJobId || '', routeItemId: route.id }));
                    }}
                  >
                    <Trans>Open</Trans>
                  </NoddiButton>
                )}
              </>
            )}
          </Stack>
        }
      />
    </Stack>
  );
}

export const RouteCancelledCard = ({ route }: { route: DetailedRouteItem }) => {
  return (
    <Stack
      alignItems='start'
      direction='column'
      p={3}
      sx={{
        borderRadius: 3,
        backgroundColor: colors.systemColors.grey,
        width: '100%',
        marginTop: 4
      }}
    >
      <Stack alignItems='start' direction='column' spacing={1}>
        <Stack alignItems='start' direction='row' spacing={1}>
          <Typography variant='body2'>{format(route.estimatedArrival, DateFormats.TIME)}</Typography>
          <Stack alignItems='center' direction='row'>
            <Typography style={{ marginRight: 4 }}>
              <Trans>Cancelled</Trans>
            </Typography>
            <NoddiIcon size='medium' name='Cross' color={colors.primary.orange} className='mr-2' />
          </Stack>
        </Stack>
        <Typography color='text.secondary'>
          <Trans>You can skip this job!</Trans>
        </Typography>
      </Stack>
    </Stack>
  );
};

export function RouteCardForDepotOrBreak({ routeItem }: { routeItem: DetailedRouteItem }) {
  const Icon =
    routeItem.type === 'Depot' ? <NoddiIcon name='Logout' size='small' /> : <NoddiIcon name='Pause' size='small' />;
  return (
    <Stack
      alignItems='start'
      direction='column'
      spacing={1}
      p={3}
      sx={{
        borderRadius: 3,
        backgroundColor: routeItem.type === 'Depot' ? colors.systemColors.outlineStroke : colors.primary.darkPurple30,
        width: '100%',
        marginTop: 2,
        border: 1,
        borderColor: routeItem.type === 'Depot' ? colors.systemColors.grey : colors.primary.purple
      }}
    >
      <Stack alignItems='center' direction='row' spacing={1}>
        {Icon}
        <Typography variant='body2'>{format(routeItem.estimatedArrival, DateFormats.TIME)}</Typography>
        <Stack alignItems='start' direction='row' spacing={1}>
          <Typography color='text.secondary' variant='body2'>
            {routeItem.type === 'Break' ? (
              'Break'
            ) : routeItem.index === 0 ? (
              <Trans>Route start</Trans>
            ) : (
              <Trans>Route end</Trans>
            )}
          </Typography>
          <Typography color='text.secondary' variant='body2'>
            {routeItem.type === 'Break'
              ? `${fromSecondsToMinutes(routeItem.expectedCompletionTimeInSeconds)} minutes`
              : routeItem.address?.fullName}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
