interface NoddiRadioButtonProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  labelClassName?: string;
}

const NoddiRadioButton = ({ checked, onChange, label, labelClassName }: NoddiRadioButtonProps) => {
  return (
    <div className='mb-4 flex items-center gap-3'>
      <input
        id={label}
        type='radio'
        checked={checked}
        onChange={onChange}
        name={label}
        className='size-4 border accent-primary-purple'
      />
      <label htmlFor={label} className={labelClassName}>
        {label}
      </label>
    </div>
  );
};

export { NoddiRadioButton };
