//@ts-nocheck
import { Trans } from '@lingui/macro';
import { Grid, Stack, Switch, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen, NoddiContainer, NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BorderCard } from '../../components/BorderCard';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import { RouteCancelledCard, RouteCardForBooking, RouteCardForDepotOrBreak } from './RouteCards';

const BookingCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 1px solid ${colors.primary.orange};
`;

const DepotCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 1px solid ${colors.systemColors.grey};
  background-color: ${colors.systemColors.outlineStroke};
`;

const BookingCompletedCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #81c784;
`;

const BreakCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.primary.darkPurple30};
  border: 1px solid ${colors.primary.darkPurple};
`;

const MyRoute = () => {
  const data = useParams<{ routeId: string }>();
  const isMobile = useIsMobile();
  const [hideCompletedAt, setHideCompletedAt] = useState(true);

  const { data: overallRouteData, isPending } = noddiAsync.useGet({
    type: URLKeys.getRouteServiceWorker,
    input: { routeId: String(data.routeId) }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!overallRouteData) {
    return <ErrorPage />;
  }

  const salesItemSummary = overallRouteData.routeItems.reduce(
    (acc, routeItem) => {
      if (routeItem.type === 'Booking') {
        routeItem.cars.forEach((car) => {
          car.salesItems.forEach((salesItem) => {
            if (acc[salesItem.name]) {
              acc[salesItem.name] += 1;
            } else {
              acc[salesItem.name] = 1;
            }
          });
        });
      }
      return acc;
    },
    {} as { [key: string]: number }
  );

  return (
    <HomeContentWrapper title='Details for route'>
      <Stack alignItems='start' direction='column' spacing={1}>
        <BorderCard style={{ width: '100%', marginBottom: 12 }}>
          <Stack direction='row' alignItems='center' style={{ marginBottom: 12 }}>
            <NoddiIcon name='Bill' className='mr-1' />
            <Typography variant='h6'>
              <Trans>Route summary</Trans>
            </Typography>
          </Stack>
          <Grid container spacing={1}>
            {Object.entries(salesItemSummary).map(([salesItem, count], idx) => {
              return (
                <Grid key={idx} item xs={isMobile ? 6 : 3}>
                  <Stack direction='row' alignItems='center'>
                    <Typography style={{ fontSize: 14, color: colors.primary.orange }} variant='h6'>
                      {salesItem}:
                    </Typography>
                    <Typography style={{ fontSize: 14 }} variant='h6'>
                      &nbsp;
                      {count}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </BorderCard>
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent='space-between'
          alignItems={isMobile ? undefined : 'center'}
          width='100%'
        >
          <Stack direction='row' spacing={1}>
            <Stack alignItems='start' direction='column' spacing={1}>
              <Typography style={{ fontSize: 14 }}>
                <Trans>Booking</Trans>
              </Typography>
              <BookingCircle />
            </Stack>
            <Stack alignItems='start' direction='column' spacing={1}>
              <Typography style={{ fontSize: 14 }}>
                <Trans>Depot</Trans>
              </Typography>
              <DepotCircle />
            </Stack>

            <Stack alignItems='start' direction='column' spacing={1}>
              <Typography style={{ fontSize: 14 }}>
                <Trans>Break</Trans>
              </Typography>
              <BreakCircle />
            </Stack>
            <Stack alignItems='start' direction='column' spacing={1}>
              <Typography style={{ fontSize: 14 }}>
                <Trans>Completed</Trans>
              </Typography>
              <BookingCompletedCircle />
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' mb={isMobile ? 2 : undefined}>
            <Typography style={{ fontSize: 14 }}>
              <b>
                <Trans>Hide completed jobs</Trans>
              </b>
            </Typography>
            <Switch onChange={(e) => setHideCompletedAt(e.target.checked)} checked={hideCompletedAt} color='warning' />
          </Stack>
        </Stack>
      </Stack>

      {overallRouteData.routeItems?.map((routeItem, index) => {
        const isLastItemOrFirstItem = index === overallRouteData.routeItems.length - 1 || index === 0;
        const prevItem = isLastItemOrFirstItem ? undefined : overallRouteData.routeItems[index - 1];
        const previousItemIsBooking = !prevItem?.isCancelled;
        return (
          <Stack key={index}>
            {routeItem.isCancelled ? (
              <RouteCancelledCard key={index} route={routeItem} />
            ) : routeItem.type === 'Booking' ? (
              hideCompletedAt && routeItem.completedAt ? null : (
                <RouteCardForBooking
                  key={index}
                  route={routeItem}
                  overAllJobId={data.routeId}
                  overallJobStartedAt={overallRouteData.startedAt}
                  estimatedArrivalPrevJob={previousItemIsBooking ? prevItem?.estimatedDeparture : undefined}
                />
              )
            ) : (
              /* If not cancelled or booking, it is a depot or break */
              <RouteCardForDepotOrBreak key={index} routeItem={routeItem} />
            )}
          </Stack>
        );
      })}
    </HomeContentWrapper>
  );
};

export default MyRoute;
