import { t } from '@lingui/macro';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiFeedbackBox } from 'noddi-ui';

interface RouteItemOnRouteStatusBoxProps {
  routeItem: RouteItemForServiceWorker;
  isNextOnRoute: boolean;
}
const RouteItemOnRouteStatusBox = ({ routeItem, isNextOnRoute }: RouteItemOnRouteStatusBoxProps) => {
  if (routeItem.cancelledAt) {
    return (
      <NoddiFeedbackBox description={t`This customer has cancelled their booking!`} variant='error' size='small' />
    );
  }
  if (routeItem.completedAt) {
    return <NoddiFeedbackBox description={t`This customer is completed!`} variant='success' size='small' />;
  }

  if (!isNextOnRoute) {
    return (
      <NoddiFeedbackBox
        description={t`Note: this is not the next customer on your route!`}
        variant='warning'
        size='small'
      />
    );
  }

  return null;
};

export { RouteItemOnRouteStatusBox };
