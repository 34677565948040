import { Trans } from '@lingui/macro';
import { useIsMobile } from 'noddi-ui';
import { useState } from 'react';

interface ShowMoreDescriptionProps {
  description: string;
  trimLimit?: number;
}

const ShowMoreDescription = ({ description, trimLimit = 70 }: ShowMoreDescriptionProps) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const isMobile = useIsMobile();

  const trimmedDescription = description.length > trimLimit ? `${description.slice(0, trimLimit)}...` : description;

  return (
    <div>
      <p>
        {showFullDescription ? description : trimmedDescription}
        {description.length > trimLimit && isMobile && (
          <span className='ml-2 cursor-pointer  underline' onClick={() => setShowFullDescription((prev) => !prev)}>
            {!showFullDescription ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}
          </span>
        )}
      </p>
    </div>
  );
};

export { ShowMoreDescription };
