import { t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { NoddiCheckbox, NoddiDatePicker } from 'noddi-ui';
import { useEffect, useState } from 'react';

import PickingListView from './PickingList';

type PickingListProps = {
  disablePersonalPickingList?: boolean;
};

export const PickingList = ({ disablePersonalPickingList = false }: PickingListProps) => {
  const [date, setDate] = useState(new Date());
  const { userData } = useAuthContext();
  const selectedWorkerId = userData?.user?.serviceWorker?.id;
  const [showPersonalList, setShowPersonalList] = useState(false);

  useEffect(() => {
    if (selectedWorkerId && !disablePersonalPickingList) {
      setShowPersonalList(true);
    }
  }, [selectedWorkerId]);

  const { noddiToast } = useNoddiToast();

  return (
    <>
      <div className='flex gap-4 rounded-xl bg-primary-white p-4'>
        <div className='flex items-end gap-4'>
          <div className='flex w-[10rem]'>
            <NoddiDatePicker label={t`Select date`} value={date} onChange={(date) => setDate(date!)} />
          </div>
        </div>
        {!disablePersonalPickingList && (
          <div className='flex items-center gap-2'>
            <label htmlFor={t`My route only`}>{t`My route only`}</label>
            <NoddiCheckbox
              name={t`My route only`}
              checked={showPersonalList}
              onChange={(event) => {
                if (selectedWorkerId) {
                  setShowPersonalList(event.target.checked);
                } else {
                  noddiToast.error(t`You don't have a worker id associated with your profile.`);
                }
              }}
            />{' '}
          </div>
        )}
      </div>
      <PickingListView selectedWorkerId={selectedWorkerId} showPersonalList={showPersonalList} date={date} />
    </>
  );
};
