import { PropsWithChildren } from 'react';

interface HomeContentWrapperProps {
  title?: string;
  subtitle?: string;
}

const HomeContentWrapper = ({ children, title, subtitle }: PropsWithChildren<HomeContentWrapperProps>) => {
  const hasTitles = title || subtitle;
  return (
    <div className='mx-auto flex max-w-screen-mdx flex-col px-6 pb-6 sm:px-10'>
      {hasTitles && (
        <div className='flex flex-col gap-3 pt-6 sm:pt-4'>
          <div className='mb-4 flex items-center justify-between'>
            <div>
              {title && <h1 className='text-7 sm:text-13'>{title}</h1>}
              {subtitle && <p>{subtitle}</p>}
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default HomeContentWrapper;
