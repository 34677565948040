import { t } from '@lingui/macro';
import { NoddiTabs, TabItem } from 'noddi-ui';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import AllRegisteredWorPreferences from './AllRegisteredWorPreferences';
import RegisterNewWorkPreference from './RegisterNewWorkPreference';

const WorkPreferences = () => {
  const newTab: TabItem<WorkPreferencesTabItemValues> = {
    label: t`Add availability`,
    value: 'new',
    node: <RegisterNewWorkPreference />
  };

  const existing: TabItem<WorkPreferencesTabItemValues> = {
    label: t`Availability`,
    value: 'existing',
    node: <AllRegisteredWorPreferences />
  };

  const tabItems: TabItem<WorkPreferencesTabItemValues>[] = [newTab, existing];

  type WorkPreferencesTabItemValues = 'new' | 'existing';

  return (
    <HomeContentWrapper>
      <NoddiTabs<WorkPreferencesTabItemValues>
        tabItems={tabItems}
        defaultValue='existing'
        sx={{ marginBottom: '1rem' }}
      />
    </HomeContentWrapper>
  );
};

export default WorkPreferences;
