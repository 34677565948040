import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import { getCurrentRouteItem, getNextRouteItem } from '../../utils/routeItem';
import BookingHeaderInfo from './BookingHeaderInfo';
import BookingOnRoute from './BookingOnRoute';
import CurrentRouteItemActionButtons from './CurrentRouteItemActionButtons';
import RouteHeader from './RouteHeader';

const ServiceWorkerRoute = () => {
  const { routeId } = useParams();

  const routeIdAsNumber = parseInt(routeId as string);

  const {
    data: route,
    isPending: isRoutePending,
    isFetching: isRouteFetching,
    error: routeError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteForServiceWorker,
    input: { routeId: routeIdAsNumber }
  });

  // we listen to isRouteFetching because we want to show loading screen while invalidating query which is not reflected in isPending
  if (isRoutePending || isRouteFetching) {
    return <LoadingScreen />;
  }
  if (routeError) {
    return <ErrorPage apiError={routeError} />;
  }

  // return first appointment that is not completed
  const currentRouteItem = getCurrentRouteItem(route.routeItems);
  const nextRouteItemAfterCurrent = getNextRouteItem(route.routeItems, currentRouteItem);

  return (
    <HomeContentWrapper>
      {currentRouteItem ? (
        <>
          <RouteHeader route={route} routeItemId={currentRouteItem.id} />
          <div className='rounded-lg bg-primary-white px-6 pb-4 pt-7'>
            <BookingHeaderInfo routeItem={currentRouteItem} />
            {currentRouteItem.startedAt && <BookingOnRoute routeItem={currentRouteItem} />}
          </div>
          <CurrentRouteItemActionButtons
            currentRouteItem={currentRouteItem}
            routeId={routeIdAsNumber}
            nextRouteItemAfterCurrent={nextRouteItemAfterCurrent}
          />
        </>
      ) : (
        <div className='mt-5 rounded-lg bg-primary-white px-6 pb-4 pt-7'>
          <p className='text-5 font-semibold text-primary-purple'>
            <Trans>Thank you!</Trans>
          </p>
          <p>
            <Trans>You have completed todays route</Trans>🎉
          </p>
        </div>
      )}
    </HomeContentWrapper>
  );
};

export default ServiceWorkerRoute;
