import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen } from 'noddi-ui';

import WorkCard from '../../components/ui/WorkCard';

export default function AllRegisteredWorPreferences() {
  const { data: workInfo, isPending: isCapacityContributionsForServiceWorkerLoading } = noddiAsync.useGet({
    type: URLKeys.getCapacityContributionsForServiceWorker
  });

  if (isCapacityContributionsForServiceWorkerLoading) {
    return <LoadingScreen />;
  }

  if (!workInfo) {
    return <ErrorPage />;
  }

  return (
    <div className='grid grid-cols-1 gap-3 md:grid-cols-2'>
      {workInfo.map((workInfo) => {
        return <WorkCard workInfo={workInfo} key={workInfo.id} />;
      })}
    </div>
  );
}
