import { t } from '@lingui/macro';
import { Profile as SharedProfile } from 'noddi-ui';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';

const EditContactInfo = () => (
  <HomeContentWrapper title={t`Edit contact info`}>
    <SharedProfile hideHeader />
  </HomeContentWrapper>
);

export default EditContactInfo;
