import { plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteForServiceWorker } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, NoddiFeedbackBox } from 'noddi-ui';
import { isToday, isTomorrow } from 'noddi-util';

import RouteCard from '../../components/ui/RouteCard';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';

const MyRoutes = () => {
  const {
    data: routes,
    isPending: isRoutesLoading,
    isFetching: isRoutesFetching,
    error: routesError
  } = noddiAsync.useGet({
    type: URLKeys.getRoutesForServiceWorker
  });

  if (isRoutesLoading || isRoutesFetching) {
    return <LoadingScreen />;
  }
  if (routesError) {
    return <ErrorPage apiError={routesError} />;
  }

  //create two lists, one for today and one for tomorrow
  const mappedRoutes = routes?.reduce(
    (acc, route) => {
      if (isToday(route.date)) {
        acc.today.push(route);
      } else if (isTomorrow(route.date)) {
        acc.tomorrow.push(route);
      }
      return acc;
    },
    { today: [], tomorrow: [] } as { today: RouteForServiceWorker[]; tomorrow: RouteForServiceWorker[] }
  );

  const todaysRoutesText = plural(mappedRoutes.today.length, {
    one: "Today's route",
    other: "Today's routes"
  });

  const tomorrowsRoutesText = plural(mappedRoutes.tomorrow.length, {
    one: "Tomorrow's route",
    other: "Tomorrow's routes"
  });

  return (
    <HomeContentWrapper>
      <div className='mb-10 mt-4 flex flex-col gap-2'>
        <p className='text-5 font-semibold'>{todaysRoutesText}</p>

        <div className='flex flex-col gap-4'>
          {mappedRoutes.today?.map((route) => {
            return <RouteCard route={route} key={route.id} />;
          })}
        </div>
        {mappedRoutes.today.length === 0 && (
          <NoddiFeedbackBox size='small' variant='info' heading={t`You have no routes for today`} />
        )}
        <p className='text-5 font-semibold'>{tomorrowsRoutesText}</p>
        <div className='flex flex-col gap-4'>
          {mappedRoutes.tomorrow?.map((route) => {
            return <RouteCard route={route} isFutureRoute key={route.id} />;
          })}
        </div>

        {mappedRoutes.tomorrow.length === 0 && (
          <NoddiFeedbackBox
            size='small'
            variant='info'
            heading={t`You currently have no assigned routes for tomorrow`}
          />
        )}
      </div>
    </HomeContentWrapper>
  );
};

export default MyRoutes;
