import { t } from '@lingui/macro';
import { getAddressYupObject } from 'noddi-ui';
import * as Yup from 'yup';

export const getValidationSchema = () =>
  Yup.object({
    dates: Yup.array()
      .of(Yup.string().required(t`Required`))
      .min(1, t`At least one date is required`)
      .required(t`Required`),
    licenseAreaId: Yup.number().required(t`Required`),
    startTime: Yup.string().required(t`Required`),
    endTime: Yup.string().required(t`Required`),
    maxDuration: Yup.number()
      .min(4, t`Max duration must be at least 4 hours`)
      .max(12, t`Max duration cannot exceed 12 hours`)
      .required('Max duration is required'),
    startAddress: Yup.lazy((value) =>
      typeof value === 'object' ? getAddressYupObject() : Yup.number().required(t`Required`)
    ),
    endAddress: Yup.lazy((value) =>
      typeof value === 'object' ? getAddressYupObject() : Yup.number().required(t`Required`)
    )
  });
