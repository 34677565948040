import { Trans } from '@lingui/macro';
import { TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ActiveBookingItem } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { ApiErrorMessage, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { fromSecondsToMinutes } from 'noddi-util';
import { useState } from 'react';

import AddService from '../../../pages/MyActiveRoute/AddService';
import ForgottenTasksModal from './Modals/ForgottenTasksModal';
import { UndoUnableToCompleteModal } from './Modals/UndoUnableToCompleteModal';
import UpdateTireMeasuresModal from './Modals/UpdateTireMeasuresModal';
import { UnableToCompleteCarModal } from './UnableToCompleteCar';

type Props = {
  bookingItem: ActiveBookingItem;
  routeItemId: string;
  routeId: string;
  licenseAreaId: number;
};

const BookingServiceCard = ({ bookingItem, routeId, routeItemId, licenseAreaId }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [unableToCompleteModal, setUnableToCompleteModal] = useState(false);
  const [undoUnableToComplete, setUndoUnableToComplete] = useState(false);
  const [forgottenTasksModalOpen, setForgottenTasksModalOpen] = useState(false);
  const { noddiToast } = useNoddiToast();

  const {
    data: shouldMeasureWheels,
    isPending: shouldMeasureWheelsCheckIsPending,
    error: getBookingItemShouldMeasureWheelsError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingItemShouldMeasureWheels,
    input: {
      bookingItemId: bookingItem.id
    }
  });

  const { mutateAsync: addLicenseNumber, isPending: isAddLicenseNumberLoading } = noddiAsync.usePost({
    type: URLKeys.postAddLicensePlateNumber,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: {
            routeItemId,
            routeId
          }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { mutateAsync: mutateCompleteCar, isPending: isCompleteCarLoading } = noddiAsync.usePost({
    type: URLKeys.postMarkCarItemInBookingComplete,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker,
          input: {
            routeItemId,
            routeId
          }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  if (getBookingItemShouldMeasureWheelsError) {
    return <ApiErrorMessage error={getBookingItemShouldMeasureWheelsError} />;
  }

  const bookingHasUnableToComplete = bookingItem.salesItems.some((item) => item.unableToComplete);

  const hasTireMeasureTask = shouldMeasureWheels;
  const hasCompletedMeasuringTask =
    bookingItem.carWheelMeasurementBookingItems.length > 0 &&
    bookingItem.carWheelMeasurementBookingItems.some((item) => item.carWheelMeasurement);
  const needsToMeasureTires = hasTireMeasureTask && !hasCompletedMeasuringTask;

  return (
    <>
      <div className='rounded-xl bg-primary-white p-4'>
        <Stack direction='row'>
          <Stack direction='column' spacing={1} marginTop={4}>
            {bookingItem.completedAt && (
              <Stack direction='row'>
                <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1 mr-2' />

                <Typography>
                  <Trans>Completed</Trans>
                </Typography>
              </Stack>
            )}

            <Typography variant='h6'>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <NoddiIcon name='Car' size='medium' className='mb-1 mr-2' /> {bookingItem.make} {bookingItem.model} -{' '}
                {bookingItem?.licensePlateNumber}
              </span>
            </Typography>

            {!bookingItem.licensePlateNumber && (
              <Stack width='100%'>
                <TextField
                  label='License number'
                  name='license_plate'
                  placeholder='ABC12345'
                  onChange={(e) => setLicenseNumber(e.target.value)}
                />
                <NoddiButton
                  style={{ marginTop: '24px' }}
                  loading={isAddLicenseNumberLoading}
                  onClick={async () => {
                    const req = {
                      carId: bookingItem.carId,
                      licensePlateNumber: licenseNumber
                    };
                    await addLicenseNumber(req);
                  }}
                >
                  <Trans>Add license number</Trans>
                </NoddiButton>
              </Stack>
            )}

            <Typography variant='button'>
              <Trans>Estimated Time: {fromSecondsToMinutes(bookingItem.serviceTimeInSeconds)} minutes</Trans>
            </Typography>
            {bookingItem.salesItems.map((item) => (
              <li key={item.id}>
                {item.name} {item.unableToComplete ? `(${item.unableToComplete.reason} ❌)` : ``}
              </li>
            ))}
          </Stack>
        </Stack>
        {needsToMeasureTires && (
          <NoddiButton
            style={{ marginTop: '24px' }}
            onClick={() => setIsOpenModal((prev) => !prev)}
            fullWidth
            variant='secondary'
          >
            <Trans>Measure tires</Trans>
          </NoddiButton>
        )}
        {!bookingItem.completedAt && (
          <>
            <NoddiButton
              style={{ marginTop: '24px' }}
              fullWidth
              loading={isCompleteCarLoading || shouldMeasureWheelsCheckIsPending}
              onClick={async () => {
                if (needsToMeasureTires) {
                  return setForgottenTasksModalOpen(true);
                }
                return mutateCompleteCar({ routeId, routeItemId, bookingItemId: bookingItem.id });
              }}
            >
              <Trans>Finish this Car</Trans>
            </NoddiButton>

            <NoddiButton
              style={{ marginTop: '24px' }}
              fullWidth
              variant='secondary'
              onClick={() => setUnableToCompleteModal((prev) => !prev)}
            >
              <Trans>Unable to Complete</Trans>
            </NoddiButton>
            {bookingItem.licensePlateNumber && (
              <AddService
                licenseAreaId={licenseAreaId}
                routeId={routeId}
                bookingItem={bookingItem}
                registrationNumber={bookingItem.licensePlateNumber}
              />
            )}
          </>
        )}
        {bookingHasUnableToComplete && (
          <NoddiButton style={{ marginTop: '24px' }} onClick={() => setUndoUnableToComplete(true)} fullWidth>
            <Trans>Undo unable to complete</Trans>
          </NoddiButton>
        )}
      </div>

      <ForgottenTasksModal
        open={forgottenTasksModalOpen}
        setOpen={setForgottenTasksModalOpen}
        bookingItemId={bookingItem.id}
        routeId={routeId}
        routeItemId={routeItemId}
      />
      <UndoUnableToCompleteModal
        routeId={routeId}
        salesItems={bookingItem.salesItems}
        routeItemId={routeItemId}
        isOpen={undoUnableToComplete}
        setIsOpen={setUndoUnableToComplete}
      />

      <UpdateTireMeasuresModal
        bookingItem={bookingItem}
        routeItemId={routeItemId}
        isOpenModal={isOpenModal}
        routeId={routeId}
        setIsOpenModal={setIsOpenModal}
      />

      <UnableToCompleteCarModal
        setUnableToCompleteModal={setUnableToCompleteModal}
        unableToCompleteModal={unableToCompleteModal}
        routeId={routeId}
        routeItemId={routeItemId}
        bookingId={bookingItem.id}
        salesItems={bookingItem.salesItems.filter((item) => !item.unableToComplete)}
      />
    </>
  );
};

export default BookingServiceCard;
