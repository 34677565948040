import { Trans } from '@lingui/macro';
import { DateFormats, format } from 'noddi-util';

import { NoddiIconButton } from '../../../molecules';

type RegisterWorkSelectedDatesProps = {
  daysToRegister: string[];
  setDaysToRegister: (dates: string[]) => void;
};

const RegisterWorkSelectedDates = ({ daysToRegister, setDaysToRegister }: RegisterWorkSelectedDatesProps) => {
  if (daysToRegister.length === 0) {
    return null;
  }
  return (
    <div className='flex flex-col gap-2 p-2'>
      <p className='opacity-70'>
        <Trans>List of added dates</Trans>
      </p>
      {daysToRegister
        .sort((a, b) => {
          return +new Date(a) - +new Date(b);
        })
        .map((date) => (
          <div className='flex items-center justify-between gap-2' key={date.toLocaleLowerCase()}>
            <p>
              <b>{format(date, DateFormats.FULL_DAY_SHORT_MONTH_AND_YEAR)}</b>
              ,&nbsp;
              {format(date, DateFormats.FULL_YEAR)}
            </p>
            <div>
              <NoddiIconButton
                iconName='Cross'
                iconSize='small'
                variant='destructive'
                onClick={() => {
                  setDaysToRegister(daysToRegister.filter((x) => x !== date));
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export { RegisterWorkSelectedDates };
