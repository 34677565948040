import { Trans, t } from '@lingui/macro';
import { CustomerCarGeneration } from 'noddi-async/src/types';
import { NoddiButton, NoddiDialog, NoddiIcon, colors } from 'noddi-ui';
import { useState } from 'react';

import { ShowMoreDescription } from '../../components/ShowMoreDescription';
import { getUniquePsiValues } from '../../utils/car';

const PsiPressureInfo = ({
  tirePressures
}: {
  tirePressures: CustomerCarGeneration['dimensions']['frontTirePsiPressures'];
}) => {
  return (
    <div>
      {tirePressures.map(({ psi, dimensions }, index) => (
        <div key={index} className='border-b py-4 last:border-0'>
          <div className='mb-2 flex items-center justify-between'>
            <div className='flex items-center'>
              <NoddiIcon name='WheelAngle' />
              <p>{t`Wheel dimensions`}</p>
            </div>
            <p className='font-bold text-4'>PSI</p>
          </div>

          <ul className='ml-6'>
            {dimensions.map((value) => (
              <li key={value}>
                <div className='flex justify-between'>
                  <p>{value}</p>
                  <p className=' ml-1 font-bold text-4'>{psi}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const PsiRow = ({
  tireInfo,
  onClick,
  text
}: {
  tireInfo: { psiValues: string; hasMultipleValues: boolean };
  onClick: () => void;
  text: string;
}) => {
  return (
    <div className='flex items-center justify-between'>
      <p>
        {text}: {tireInfo.psiValues} PSI
      </p>
      {tireInfo.hasMultipleValues && (
        <NoddiButton startIcon='ArrowSquareRightCorner' className='py-0' variant='link' onClick={onClick}>
          <Trans>Details</Trans>
        </NoddiButton>
      )}
    </div>
  );
};

interface CarSpecificationsProps {
  carGeneration: CustomerCarGeneration;
}

const CarSpecifications = ({ carGeneration }: CarSpecificationsProps) => {
  const { frontTirePsiPressures, rearTirePsiPressures } = carGeneration.dimensions;
  const uniqueFrontTireValues = getUniquePsiValues(frontTirePsiPressures);
  const uniqueRearTireValues = getUniquePsiValues(rearTirePsiPressures);

  const [moreInfoFrontTires, setMoreInfoFrontTires] = useState(false);
  const [moreInfoRearTires, setMoreInfoRearTires] = useState(false);

  const hasTorqueValues = carGeneration.wheelLugNutTorqueAluminiumNm && carGeneration.wheelLugNutTorqueSteelNm;

  const isEqualTorqueValue = carGeneration.wheelLugNutTorqueAluminiumNm === carGeneration.wheelLugNutTorqueSteelNm;

  return (
    <div>
      {carGeneration.makeInformation && (
        <div>
          <p>{carGeneration.makeInformation}</p>
        </div>
      )}
      {uniqueFrontTireValues.psiValues && (
        <PsiRow
          text={t`Front tire pressure`}
          tireInfo={uniqueFrontTireValues}
          onClick={() => setMoreInfoFrontTires(true)}
        />
      )}
      {uniqueRearTireValues.psiValues && (
        <PsiRow
          text={t`Rear tire pressure`}
          tireInfo={uniqueRearTireValues}
          onClick={() => setMoreInfoRearTires(true)}
        />
      )}

      {isEqualTorqueValue ? (
        <p>
          <Trans>Torque</Trans>:
          {hasTorqueValues ? `${carGeneration.wheelLugNutTorqueAluminiumNm} Nm` : <Trans>Not specified</Trans>}
        </p>
      ) : (
        <div>
          <p>
            <Trans>Torque aluminium</Trans>: {carGeneration.wheelLugNutTorqueAluminiumNm} Nm
          </p>
          <p>
            <Trans>Torque steel</Trans>: {carGeneration.wheelLugNutTorqueSteelNm} Nm
          </p>
        </div>
      )}
      {carGeneration.information && (
        <div className='mt-1 flex gap-2'>
          <div>
            <NoddiIcon name='Info' color={colors.signal.warning} />
          </div>
          <ShowMoreDescription description={carGeneration.information} />
        </div>
      )}
      {carGeneration.makeInformation && (
        <div className='flex items-center'>
          <NoddiIcon name='Info' color={colors.signal.warning} />
          <ShowMoreDescription description={carGeneration.makeInformation} />
        </div>
      )}
      {moreInfoFrontTires && (
        <NoddiDialog
          title={t`Front tire pressure`}
          open={moreInfoFrontTires}
          onClose={() => setMoreInfoFrontTires(false)}
        >
          <PsiPressureInfo tirePressures={frontTirePsiPressures} />
        </NoddiDialog>
      )}

      {moreInfoRearTires && (
        <NoddiDialog title={t`Rear tire pressure`} open={moreInfoRearTires} onClose={() => setMoreInfoRearTires(false)}>
          <PsiPressureInfo tirePressures={rearTirePsiPressures} />
        </NoddiDialog>
      )}
    </div>
  );
};
export default CarSpecifications;
