import { Trans } from '@lingui/macro';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { DEFAULT_LICENSE_PLATE_COUNTRY_CODE, URLKeys, noddiAsync } from 'noddi-async';
import { ActiveBookingItem, SalesCar } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiAsyncError,
  NoddiButton,
  NoddiDialog,
  NoddiLinearProgressLoader
} from 'noddi-ui';
import { Dispatch, SetStateAction, useState } from 'react';

import { SalesItemDto } from '../../hooks/apiHooks/useGetSalesItems';
import SelectSalesItem from './NewCarForm/SelectSalesItem';
import { convertRegisteredCarToSalesCar } from './NewCarForm/helper';

type Props = {
  licenseAreaId: number;
  routeId: string;
  bookingItem: ActiveBookingItem;
  registrationNumber: string;
};

type ServiceSelectorProps = Omit<Props, 'registrationNumber'> & {
  salesCar: SalesCar;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ServiceSelector = ({ salesCar, licenseAreaId, setOpen, bookingItem }: ServiceSelectorProps) => {
  const [selectedSalesItems, setSelectedSalesItems] = useState<SalesItemDto[]>([]);
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: addSalesItemsToBooking,
    isPending,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postAddExtraSalesItemsToBookingItem,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker
        });
        setOpen(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  return (
    <SelectSalesItem
      licenseAreaId={licenseAreaId}
      carSearchResult={salesCar}
      selectedSalesItems={selectedSalesItems}
      setSelectedSalesItems={setSelectedSalesItems}
      excludeSalesItemIds={bookingItem.salesItems.map((x) => x.id)}
      fetchForNewCar={false}
      onAddService={async ({ salesItemIds }) => {
        const req = {
          id: bookingItem.id,
          salesItemIds
        };
        await addSalesItemsToBooking(req);
      }}
    />
  );
};

const AddService = ({ licenseAreaId, routeId, registrationNumber, bookingItem }: Props) => {
  const [open, setOpen] = useState(false);

  const { data: carResponse, isPending } = noddiAsync.useGet({
    type: URLKeys.getDataFromLicensePlateNumber,
    input: { licensePlateNumber: registrationNumber, countryCode: DEFAULT_LICENSE_PLATE_COUNTRY_CODE },
    queryConfig: { enabled: !!registrationNumber }
  });

  const salesCar = carResponse && convertRegisteredCarToSalesCar(carResponse);

  if (isPending) {
    return <NoddiLinearProgressLoader />;
  }
  return (
    <>
      <NoddiButton style={{ marginTop: '24px' }} onClick={() => setOpen(true)} fullWidth variant='secondary'>
        <Trans>Add new service</Trans>
      </NoddiButton>

      <NoddiDialog maxWidth='sm' fullWidth open={open}>
        <DialogTitle>Add new service</DialogTitle>
        <DialogContent>
          <Typography>
            <Trans>
              Use this if you want to add more services to existing appointment. When you click on the confirm button,
              the selected services will be added to the appointment.
            </Trans>
          </Typography>
          {salesCar && (
            <ServiceSelector
              licenseAreaId={licenseAreaId}
              salesCar={salesCar}
              setOpen={setOpen}
              routeId={routeId}
              bookingItem={bookingItem}
            />
          )}
        </DialogContent>
      </NoddiDialog>
    </>
  );
};

export default AddService;
