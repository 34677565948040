import { IconName, NoddiIcon, colors } from 'noddi-ui';

type NoddiClickableItemProps = {
  title?: string;
  description?: string;
  startIconName: IconName;
  onClick: () => void;
};

const NoddiClickableItem = ({ title, startIconName, description, onClick }: NoddiClickableItemProps) => {
  return (
    <button
      className='flex min-w-[300px] items-center gap-2 rounded-lg bg-primary-white px-4 py-2.5 text-start'
      onClick={() => {
        onClick();
      }}
    >
      <NoddiIcon size='large' name={startIconName} color={colors.primary.black} />
      <div className='flex flex-col'>
        {title && <p>{title}</p>}
        {description && <p className=' text-3 opacity-50'>{description}</p>}
      </div>

      <NoddiIcon className='ml-auto' name='Plus' />
    </button>
  );
};

export { NoddiClickableItem };
