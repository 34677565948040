import { Trans, t } from '@lingui/macro';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserRoleType, useAuthContext } from 'noddi-provider';
import { hasValidPermission } from 'noddi-provider/src/utils';
import { useState } from 'react';

import { NoddiButton } from '../../../molecules/NoddiButton';
import { mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';

interface EmailLoginProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  requiredLoginLevels?: UserRoleType[];
  isOrgApp?: boolean;
}

const EmailLogin = ({ setErrorMessage, requiredLoginLevels, isOrgApp }: EmailLoginProps) => {
  const { loginUser, updateUserData } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { isPending, mutateAsync: login } = noddiAsync.usePost({
    type: URLKeys.postEmailLogin,
    queryConfig: {
      onSuccess: (res) => {
        const { user } = res.data;

        // Permission check
        if (requiredLoginLevels && !hasValidPermission(requiredLoginLevels, user)) {
          setErrorMessage(
            t`Access denied. If you believe this is a mistake or require permission, please contact us at hei@noddi.no.`
          );
          return;
        }

        if (isOrgApp) {
          const isMemberOfAnyOrganization = user.userGroups.some((group) => group.isOrganization);
          if (!isMemberOfAnyOrganization) {
            setErrorMessage(t`You are not connected to any organizations.`);
            return;
          }
        }

        // Log in user and reset form fields
        loginUser(res.data);
        setEmail('');
        setPassword('');

        if (isOrgApp) {
          // Select organization and update user groups
          const organizationToSelect = user.userGroups.find((ug) => ug.isOrganization);
          if (organizationToSelect) {
            const updatedUserGroups = user.userGroups.map((ug) => ({
              ...ug,
              isSelected: ug.id === organizationToSelect.id
            }));

            updateUserData({
              ...res.data,
              user: { ...user, userGroups: updatedUserGroups }
            });
          }
        }
      },
      onError: (error) => {
        setErrorMessage(mapErrorCodeToUiMessage({ error }));
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        await login({ email, password });
      }}
    >
      <Stack spacing={3}>
        <TextField
          autoFocus
          fullWidth
          label='Email Address'
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label='Password'
          name='password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>
      <NoddiButton style={{ marginTop: '24px' }} disabled={isPending} type='submit' fullWidth>
        <Trans>Logg inn</Trans>
      </NoddiButton>
    </form>
  );
};

export { EmailLogin };
