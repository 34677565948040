import { Trans, plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteForServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { NoddiAsyncError, NoddiButton, NoddiDialog, NoddiIcon } from 'noddi-ui';
import { DateFormats, differenceBetweenDates, format } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';
import CustomModal from '../../commons/Modal';
import RouteDetails from './RouteDetails';
import RoutePaymentDetails from './RoutePaymentDetails';

export interface RouteCardProps {
  route: RouteForServiceWorker;
  isFutureRoute?: boolean;
}

export default function RouteCard({ route, isFutureRoute }: RouteCardProps) {
  const [openStartRouteModal, setOpenStartRouteModal] = useState(false);
  const [openConfirmRouteModal, setOpenConfirmRouteModal] = useState(false);
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false);
  const [isRouteOverviewOpen, setIsRouteOverviewOpen] = useState(false);

  const navigate = useNavigate();
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: mutateStartRoute,
    error: startRouteError,
    isPending: isStartRouteLoading
  } = noddiAsync.usePost({
    type: URLKeys.postStartRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesForServiceWorker });

        setOpenStartRouteModal(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const {
    mutateAsync: mutateConfirmRoute,
    error: confirmRouteError,
    isPending: isConfirmRouteLoading
  } = noddiAsync.usePost({
    type: URLKeys.postConfirmRouteForServiceWorker,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({ urlKey: URLKeys.getRoutesForServiceWorker });
        setOpenStartRouteModal(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const routeTime = `${format(route.startDatetime, DateFormats.TIME)} - ${format(route.endDatetime, DateFormats.TIME)}`;
  const totalRouteTime = Math.floor(differenceBetweenDates(route.endDatetime, route.startDatetime, 'hours'));
  const numberOfCustomers = route.numberOfStops;

  const numberOfCustomersText = plural(numberOfCustomers, {
    one: '# customer',
    other: '# customers'
  });

  const numberOfCars = route.numberOfCars;
  const numberOfCarsText = plural(numberOfCars, {
    one: '# car',
    other: '# cars'
  });

  const totalRouteText = plural(totalRouteTime, {
    one: '~# hour',
    other: '~# hours'
  });

  return (
    <div className='rounded-lg bg-primary-white px-4 py-3'>
      <div className='flex w-full flex-col justify-between gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='Calendar' />
            <p className='text-3'>{format(route.date, DateFormats.FULL_DAY_MONTH)}</p>
          </div>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='ClockCircle' />
            <p className='text-3'>
              {routeTime} ({totalRouteText})
            </p>
          </div>
          <div className='flex items-center gap-[4.4px]'>
            <NoddiIcon className='ml-[2.5px]' name='Car' />
            <p className='text-3'>{Math.floor(route.numberOfMeters / 1000)} km</p>
          </div>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='User' />
            <p className='text-3'>
              {numberOfCustomersText}, {numberOfCarsText}
            </p>
          </div>
          <NoddiButton
            startIcon='Bill'
            className='p-0 !text-3'
            variant='link'
            onClick={() => setIsPaymentDetailsOpen((prev) => !prev)}
          >
            <Trans>See payment details</Trans>
          </NoddiButton>
          <NoddiButton
            startIcon='MapArrows'
            className='p-0 !text-3'
            variant='link'
            onClick={() => setIsRouteOverviewOpen((prev) => !prev)}
          >
            <Trans>Show route details</Trans>
          </NoddiButton>
        </div>
        <div className='flex flex-col gap-3'>
          {route.startedAt && (
            <NoddiButton
              fullWidth
              variant='link'
              size='small'
              onClick={() => navigate(routes.todaysAppointments.getPath({ routeId: route.id }))}
            >
              Open route (BETA)
            </NoddiButton>
          )}
          <div className='flex gap-2'>
            <NoddiButton
              size='small'
              variant='secondary'
              fullWidth
              onClick={() => navigate(routes.routeDetail.getPath({ routeId: route.id }))}
            >
              {route.startedAt ? <Trans>Open route</Trans> : <Trans>Preview route</Trans>}
            </NoddiButton>

            {!route?.confirmedAt && (
              <NoddiButton
                fullWidth
                size='small'
                variant='secondary'
                onClick={() => setOpenConfirmRouteModal((prev) => !prev)}
              >
                <Trans>Confirm route</Trans>
              </NoddiButton>
            )}
          </div>
          {!route.startedAt && !isFutureRoute && (
            <NoddiButton size='small' fullWidth onClick={() => setOpenStartRouteModal((prev) => !prev)}>
              <Trans>Start route</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
      <NoddiDialog
        onClose={() => setIsPaymentDetailsOpen(false)}
        open={isPaymentDetailsOpen}
        title={t`Payment details`}
      >
        <RoutePaymentDetails routeId={route.id} />
      </NoddiDialog>
      <NoddiDialog
        fullWidth
        onClose={() => setIsRouteOverviewOpen(false)}
        open={isRouteOverviewOpen}
        title={t`Route details`}
      >
        <RouteDetails routeItems={route.routeItems} />
      </NoddiDialog>
      <CustomModal
        loading={isStartRouteLoading}
        isOpen={openStartRouteModal}
        error={startRouteError}
        handleOpen={() => setOpenStartRouteModal((prev) => !prev)}
        handleClick={async () => {
          await mutateStartRoute({ routeId: route.id });
        }}
        variant='info'
        text='Are you sure you want to start this route?'
      />
      <CustomModal
        loading={isConfirmRouteLoading}
        isOpen={openConfirmRouteModal}
        error={confirmRouteError}
        handleOpen={() => setOpenConfirmRouteModal((prev) => !prev)}
        handleClick={async () => {
          await mutateConfirmRoute({ routeId: route.id });
        }}
        variant='info'
        text='Are you sure you want to confirm this route?'
      />
    </div>
  );
}
