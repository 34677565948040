import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen, NoddiButton, NoddiClickableItem, NoddiDrawer, NoddiFeedbackBox } from 'noddi-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomModal from '../../components/commons/Modal';
import BookingServiceCard from '../../components/ui/BookingServiceCard';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import AddExtraCarModal from './AddExtraCar';
import CompletedModalContent from './CompletedModalContent';
import ContactPersons from './ContactPersons';
import BookingItemsSpecifications from './Specifications';

const CustomerOnRoute = () => {
  const [isExtraCarModalOpen, setIsExtraCarModalOpen] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);
  const [isCarWheelSetSpecificationsDrawerOpen, setIsCarWheelSetSpecificationsDrawerOpen] = useState(false);
  const [isContactPersonsDrawerOpen, setIsContactPersonsDrawerOpen] = useState(false);
  const { routeId, routeItemId } = useParams();

  const { data: routeData, isPending } = noddiAsync.useGet({
    type: URLKeys.getRouteItemsDetailsForServiceWorker,
    input: { routeId: String(routeId), routeItemId: String(routeItemId) }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!routeData) {
    return <ErrorPage />;
  }

  const shouldDisableFinishAppointmentButton = routeData.bookingItems.some((booking) => !booking.completedAt);

  const hasContactPersons = routeData.booking.contactPersons.length > 0;

  return (
    <HomeContentWrapper title={t`Customer`}>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4'>
          {routeData.commentsAdmin && (
            <p>
              <b>
                <Trans>Admin comment</Trans>
              </b>{' '}
              - {routeData.commentsAdmin}
            </p>
          )}
          {routeData.commentsUser && (
            <p>
              <b>
                <Trans>User comment</Trans>
              </b>{' '}
              - {routeData.commentsUser}
            </p>
          )}

          <div className='flex gap-2'>
            <NoddiButton startIcon='Hammer' onClick={() => setIsCarWheelSetSpecificationsDrawerOpen(true)}>
              {t`Specifications`}
            </NoddiButton>

            <NoddiDrawer
              isOpen={isCarWheelSetSpecificationsDrawerOpen}
              onClose={() => setIsCarWheelSetSpecificationsDrawerOpen(false)}
              anchor='right'
            >
              <BookingItemsSpecifications bookingItems={routeData.bookingItems} />
            </NoddiDrawer>

            {hasContactPersons && (
              <>
                <NoddiButton startIcon='User' onClick={() => setIsContactPersonsDrawerOpen(true)}>
                  {t`Contact persons`}
                </NoddiButton>

                <NoddiDrawer
                  isOpen={isContactPersonsDrawerOpen}
                  onClose={() => setIsContactPersonsDrawerOpen(false)}
                  anchor='right'
                >
                  <ContactPersons contactPersons={routeData.booking.contactPersons} />
                </NoddiDrawer>
              </>
            )}
          </div>

          <div className='flex flex-col gap-4'>
            {routeData.bookingItems.map((bookingItem) => {
              return (
                <BookingServiceCard
                  bookingItem={bookingItem}
                  routeId={routeId ?? ''}
                  routeItemId={routeItemId ?? ''}
                  licenseAreaId={routeData.licenseAreaId}
                />
              );
            })}
          </div>
        </div>
        <CustomModal
          isOpen={completedModal}
          handleOpen={() => {
            setCompletedModal((prev) => !prev);
          }}
          handleClick={() => {
            return;
          }}
          variant='form'
          text={
            <CompletedModalContent
              routeData={routeData}
              routeItemId={routeItemId}
              setCompletedModal={setCompletedModal}
            />
          }
        />
        <NoddiClickableItem
          description={t`Does the customer want more cars serviced now?`}
          onClick={() => setIsExtraCarModalOpen(true)}
          title={t`Add car`}
          startIconName='Car'
        />
        {!!routeData.licenseAreaId && isExtraCarModalOpen && (
          <AddExtraCarModal
            isOpen={isExtraCarModalOpen}
            setOpen={setIsExtraCarModalOpen}
            licenseAreaId={routeData.licenseAreaId}
            bookingId={routeData.booking.id}
          />
        )}
        {!routeData.completedAt && (
          <div className='mb-4'>
            {shouldDisableFinishAppointmentButton ? (
              <>
                <NoddiFeedbackBox
                  variant='info'
                  size='small'
                  description={t`You need to finish all cars in order to mark an appointment as finished`}
                />
              </>
            ) : (
              <NoddiButton
                disabled={shouldDisableFinishAppointmentButton}
                onClick={() => {
                  setCompletedModal(true);
                }}
                fullWidth
              >
                <Trans>Finish Appointment</Trans>
              </NoddiButton>
            )}
          </div>
        )}
      </div>
    </HomeContentWrapper>
  );
};

export default CustomerOnRoute;
