import { Trans, plural, t } from '@lingui/macro';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiButton, NoddiChip, NoddiIcon } from 'noddi-ui';
import { DateFormats, format, intervalToDuration } from 'noddi-util';

interface RouteDetailsProps {
  routeItems: RouteItemForServiceWorker[];
  onNavigateToRouteItem?: (routeItemId: number) => void;
}

const RouteDetails = ({ routeItems, onNavigateToRouteItem }: RouteDetailsProps) => {
  return (
    <div>
      {routeItems.map((routeItem, index) => {
        const numberOfCars = routeItem.numberOfCars;

        const numberOfCarsText = plural(numberOfCars, {
          one: `# car`,
          other: `# cars`
        });

        const serviceTime = intervalToDuration({ start: 0, end: routeItem.serviceTimeInSeconds * 1000 });

        const totalRouteTime = serviceTime.hours
          ? `${serviceTime.hours} hours ${serviceTime.minutes} min`
          : `${serviceTime.minutes} min`;

        const orderLineDescriptions = routeItem.orderLineDescriptions.join(', ');

        const expectedCustomerArrival = `${format(routeItem.estimatedArrival, DateFormats.TIME)} - ${format(routeItem.estimatedDeparture, DateFormats.TIME)}`;
        const routeIsCancelledOrCompleted = routeItem.completedAt || routeItem.cancelledAt;

        return (
          <div key={routeItem.id} className='mt-2 rounded-lg bg-systemColors-purpleBg p-4'>
            <div className='flex items-center justify-between'>
              <p className='font-bold'>
                <Trans>Customer</Trans> {index + 1}
              </p>
              {onNavigateToRouteItem && (
                <NoddiButton
                  onClick={() => onNavigateToRouteItem(routeItem.id)}
                  variant='link'
                  startIcon='ArrowSquareRightCorner'
                >
                  <Trans>Go to job</Trans>
                </NoddiButton>
              )}
            </div>
            <div className='mt-1 flex items-center gap-2'>
              <div>
                <NoddiIcon name='LocationPin' />
              </div>
              <p>
                {routeItem.address.streetName} {routeItem.address.streetNumber}
              </p>
            </div>

            {routeIsCancelledOrCompleted ? (
              <div className='mt-2'>
                <NoddiChip
                  label={routeItem.completedAt ? t`Completed` : t`Cancelled`}
                  variant={routeItem.completedAt ? 'success' : 'destructive'}
                  icon={routeItem.completedAt ? 'Check' : 'Cross'}
                />
              </div>
            ) : (
              <>
                <div className='mt-1 flex gap-2'>
                  <div>
                    <NoddiIcon name='Car' />
                  </div>
                  <p>
                    {numberOfCarsText}, {totalRouteTime}, {orderLineDescriptions}
                  </p>
                </div>
                <div className='mt-1 flex items-center gap-2'>
                  <div>
                    <NoddiIcon name='ClockCircle' />
                  </div>
                  <p>{expectedCustomerArrival}</p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RouteDetails;
