import { Trans } from '@lingui/macro';
import { useLanguageContext } from 'noddi-provider';
import { NoddiButton, NoddiIconButton, NoddiLanguageSelector } from 'noddi-ui';
import { useLocation, useNavigate } from 'react-router-dom';

import { getSupportedLocales } from '../App';
import routes from '../appRoutes';
import NavWrapper from './NavWrapper';

interface HomeHeaderNavProps {
  onNavOpen: () => void;
}

export const HomeHeaderNav = ({ onNavOpen }: HomeHeaderNavProps) => {
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isBaseRouterPath = Object.values(routes).some(
    (route) => pathname.includes(route.getBasePath()) && route?.isBaseRouterPath
  );

  return (
    <NavWrapper>
      <div className='flex w-full items-center gap-2'>
        {!isBaseRouterPath && (
          <NoddiIconButton
            className='border-0'
            onClick={() => navigate(-1)}
            iconName='ArrowLeft'
            iconSize='medium'
            variant='secondary'
          />
        )}
        <NoddiLanguageSelector
          supportedLanguages={getSupportedLocales()}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
        <div className='ml-auto flex gap-3 '>
          <NoddiButton variant='ghost' endIcon='HamburgerMenu' onClick={onNavOpen}>
            <Trans>Menu</Trans>
          </NoddiButton>
        </div>
      </div>
    </NavWrapper>
  );
};
