import { Trans } from '@lingui/macro';
import { RouteSettlementPayment } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

function FutureRoutePayment({ routeSettlement }: { routeSettlement: RouteSettlementPayment }) {
  const estimatedPayment = Math.max(
    parseInt(routeSettlement.performancePayment ?? '0'),
    parseInt(routeSettlement.computedMinimumPayment ?? '0')
  );

  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');
  return (
    <div className='flex flex-col gap-2'>
      <p className='font-semibold'>
        💵 <Trans>Estimated Payment details</Trans>
      </p>
      <p>
        <Trans>
          We think this route will pay <b>{formatCurrencyAmount(estimatedPayment)}</b>.
        </Trans>
      </p>
      <p>
        <Trans>
          This is based on a payment for the actuals cars you service on{' '}
          <b>{formatCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}</b>, and payment for driving &
          waiting of <b> {formatCurrencyAmount(waitAndDriveTimePayment)}</b>.
        </Trans>
      </p>
      <p>
        <Trans>
          In addition, the mileage payment is estimated to be{' '}
          <b> {formatCurrencyAmount(Number(routeSettlement.kilometerPayment))}</b>. Road toll cost will be calculated
          after the route is completed.
        </Trans>
      </p>
    </div>
  );
}

function getSalesItemsSummary(routeSettlement: RouteSettlementPayment) {
  return routeSettlement.salesItems.reduce(
    (acc, salesItem) => {
      const exists = acc[salesItem.id];
      if (exists) {
        acc[salesItem.id] = {
          ...salesItem,
          name: salesItem.name,
          count: exists.count + 1
        };
      } else {
        acc[salesItem.id] = {
          name: salesItem.name,
          count: 1,
          carSizes: salesItem.carSizes
        };
      }
      return acc;
    },
    {} as {
      [key: string]: {
        name: string;
        count: number;
        carSizes: { id: number; name: string }[];
      };
    }
  );
}

export const Tasks = ({
  routeSettlement,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  isFutureRoute?: boolean;
}) => {
  const salesItemSummary = getSalesItemsSummary(routeSettlement);

  return (
    <div className='flex flex-col gap-2'>
      <p className='my-2 text-5 font-semibold'>
        🧰 <Trans>Tasks</Trans> {isFutureRoute ? '' : 'performed'}:
      </p>
      {Object.entries(salesItemSummary).map(([key, { name, count, carSizes }]) => (
        <div
          key={key}
          className='mt-1 p-2 [&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-primary-purple'
        >
          <p>{name}</p>
          <p>
            <Trans>Car size:</Trans> {carSizes.map((carSize) => carSize.name).join(', ')}
          </p>
          <p>
            <Trans>Quantity on this route:</Trans> {count}
          </p>
        </div>
      ))}
    </div>
  );
};

export const Payment = ({
  routeSettlement,
  isFutureRoute
}: {
  routeSettlement: RouteSettlementPayment;
  isFutureRoute?: boolean;
}) => {
  const hasUnableToCompletePayment = parseInt(routeSettlement.unableToCompletePayment ?? '0') > 0;
  const waitAndDriveTimePayment =
    parseInt(routeSettlement?.waitingTimePayment ?? '0') + parseInt(routeSettlement?.drivingTimePayment ?? '0');

  const totalPayment = parseInt(routeSettlement.settledPayment ?? '0') + parseInt(routeSettlement.extraPayment ?? '0');
  return (
    <>
      {isFutureRoute ? (
        <FutureRoutePayment routeSettlement={routeSettlement} />
      ) : (
        <>
          <p>
            <Trans>Wait & drive time payment: </Trans>
            <b>
              {formatCurrencyAmount(
                parseInt(routeSettlement?.waitingTimePayment ?? '0') +
                  parseInt(routeSettlement?.drivingTimePayment ?? '0')
              )}
            </b>
          </p>
          {hasUnableToCompletePayment ? (
            <p>
              <Trans>
                You got <b>{formatCurrencyAmount(Number(routeSettlement.unableToCompletePayment))}</b> from jobs that
                you were not able to complete.
              </Trans>
            </p>
          ) : null}
          <p>
            <Trans>The payout from this route is in total</Trans> <b> {formatCurrencyAmount(totalPayment)}</b>.{' '}
            <Trans>This is based on a payment from performed jobs of</Trans>
            <b> {formatCurrencyAmount(Number(routeSettlement.sumSalesItemsLevel1Payment))}</b>{' '}
            <Trans>and payment from the time spent in your car of </Trans>
            <b>{formatCurrencyAmount(waitAndDriveTimePayment)}</b>.
            {parseInt(routeSettlement.extraPayment) > 0 && (
              <Trans>
                You've also received an extra payment of{' '}
                <b>{formatCurrencyAmount(Number(routeSettlement.extraPayment))}</b>
                {routeSettlement.extraPaymentDescription ? ` (${routeSettlement.extraPaymentDescription}).` : '.'}
              </Trans>
            )}
          </p>
          <p>
            <Trans>
              The additional payment from this route is{' '}
              <b> {formatCurrencyAmount(Number(routeSettlement.kilometerPayment))}</b> for the kilometers traveled, and{' '}
              <b>{formatCurrencyAmount(Number(routeSettlement.tollCost))}</b> in road toll.
            </Trans>
          </p>

          {routeSettlement.performancePayment === routeSettlement.computedMinimumPayment && (
            <p>
              <Trans>Minimum payment</Trans> {isFutureRoute ? 'is estimated to' : 'was'}:
              {formatCurrencyAmount(Number(routeSettlement.computedMinimumPayment))}
            </p>
          )}
        </>
      )}
    </>
  );
};
