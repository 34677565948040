import { Drawer } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen } from 'noddi-ui';
import { DrawerContent, PaperProps } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/SideNav';
import { useMobileNav } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/useMobileNav';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import routes from '../appRoutes';
import { getNavItems } from '../pages/NavItems';
import { HomeHeaderNav } from './HomeHeaderNav';

/**
 * A wrapper component for VerticalLayout that handles translation of navigation items.
 *
 * The reason for this component's existence is to have let the navItems be translated, which wasn't the
 * case with the previous implementation of VerticalLayout which only read the navItems on mount
 */
const HomePrivateLayout = () => {
  const { isLoggedIn, tokenLoginError, userData, isTokenLoginLoading } = useAuthContext();

  const mobileNav = useMobileNav();

  const navItems = getNavItems();
  const { pathname } = useLocation();

  //Closes the MobileNav after user clicks one of the menu items (route change)

  // Is there not a smarter way to do this? :3
  useEffect(() => {
    mobileNav.handleClose();
  }, [pathname]);

  if (!userData && isTokenLoginLoading) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getPath()} />;
  }

  if (tokenLoginError) {
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getPath()} />;
    }
  }

  return (
    <div className='min-h-screen bg-pint-to-lighterPint-gradient'>
      <HomeHeaderNav onNavOpen={mobileNav.handleOpen} />

      <Drawer
        anchor='left'
        onClose={mobileNav.handleClose}
        open={mobileNav.open}
        PaperProps={PaperProps()}
        variant='temporary'
        className='!z-[10000]'
      >
        <DrawerContent navItems={navItems} customPathToProfile={routes.profile.getPath()} />
      </Drawer>

      <Outlet />
    </div>
  );
};

export default HomePrivateLayout;
