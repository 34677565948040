import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import routes from '../../appRoutes';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import { getCurrentRouteItem, getNextRouteItem } from '../../utils/routeItem';
import BookingHeaderInfo from './BookingHeaderInfo';
import BookingOnRoute from './BookingOnRoute';
import CurrentRouteItemActionButtons from './CurrentRouteItemActionButtons';
import RouteHeader from './RouteHeader';
import { RouteItemOnRouteStatusBox } from './RouteItemOnRouteStatusBox';

const RouteItemOnRoute = () => {
  const { routeId } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const routeItemId = searchParams.get('routeItemId');

  const routeIdAsNumber = parseInt(routeId as string);
  const routeItemIdAsNumber = parseInt(routeItemId as string);

  const {
    data: route,
    isPending: isRoutePending,
    isFetching: isRouteFetching,
    error: routeError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteForServiceWorker,
    input: { routeId: routeIdAsNumber }
  });

  // we listen to isRouteFetching because we want to show loading screen while invalidating query which is not reflected in isPending
  if (isRoutePending || isRouteFetching) {
    return <LoadingScreen />;
  }
  if (routeError) {
    return <ErrorPage apiError={routeError} />;
  }

  const routeItem = route.routeItems.find((item) => item.id === routeItemIdAsNumber);
  const upcomingRouteItem = getCurrentRouteItem(route.routeItems);
  const nextRouteItemAfterCurrent = getNextRouteItem(route.routeItems, upcomingRouteItem);
  const isNextOnRoute = routeItem?.id === upcomingRouteItem?.id;

  const isFinished = routeItem?.cancelledAt || routeItem?.completedAt;

  return (
    <HomeContentWrapper>
      {routeItem ? (
        <>
          <RouteHeader route={route} routeItemId={routeItem.id} />
          {!isNextOnRoute && (
            <div className='mb-2'>
              <RouteItemOnRouteStatusBox routeItem={routeItem} isNextOnRoute={isNextOnRoute} />
            </div>
          )}

          <div className='rounded-lg bg-primary-white px-6 pb-4 pt-7'>
            <BookingHeaderInfo routeItem={routeItem} />
            {routeItem.startedAt && !isFinished && <BookingOnRoute routeItem={routeItem} />}
          </div>
          <CurrentRouteItemActionButtons
            onNavigateOnComplete={() => navigate(routes.todaysAppointments.getPath({ routeId: routeIdAsNumber }))}
            currentRouteItem={routeItem}
            routeId={routeIdAsNumber}
            nextRouteItemAfterCurrent={isNextOnRoute ? nextRouteItemAfterCurrent : null}
          />
        </>
      ) : (
        <div className='mt-5 rounded-lg bg-primary-white px-6 pb-4 pt-7'>
          <p className='text-5 font-semibold text-primary-purple'>
            <Trans>Thank you!</Trans>
          </p>
          <p>
            <Trans>You have completed todays route</Trans>🎉
          </p>
        </div>
      )}
    </HomeContentWrapper>
  );
};

export default RouteItemOnRoute;
