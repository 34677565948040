import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { SalesCar } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import { ApiErrorMessage, NoddiAsyncError, NoddiButton, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';

import { SalesItemDto } from '../../hooks/apiHooks/useGetSalesItems';
import CarSearchResult from './NewCarForm/CarSearchResult';
import LicensePlateSearch from './NewCarForm/LicensePlateSearch';
import SelectSalesItem from './NewCarForm/SelectSalesItem';
import SelectedCars from './NewCarForm/SelectedCars';

const AddExtraCarModal = ({
  licenseAreaId,
  bookingId,
  isOpen,
  setOpen
}: {
  licenseAreaId: number;
  bookingId: number;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const [carSearchResult, setCarSearchResult] = useState<SalesCar | null>(null);
  const [isSelectingCar, setIsSelectingCar] = useState(false);
  const [selectedCar, setSelectedCar] = useState<SalesCar | null>(null);
  const [selectedSalesItems, setSelectedSalesItems] = useState<SalesItemDto[]>([]);
  const [regNumber, setRegNumber] = useState<string>('');
  const [showCarSearchResult, setShowCarSearchResult] = useState<boolean>(false);
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: addBookingItemToExistingBooking,
    error,
    isPending: isAddBookingItemPending
  } = noddiAsync.usePost({
    type: URLKeys.postAddBookingItemToExistingBooking,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({
          urlKey: URLKeys.getRouteItemsDetailsForServiceWorker
        });
        invalidateQueryKey({
          urlKey: URLKeys.getBookingItemsForRoute
        });
        setSelectedCar(null);
        setSelectedSalesItems([]);
        setRegNumber('');
        setCarSearchResult(null);
        setOpen(false);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.asyncError(error);
      }
    }
  });

  return (
    <NoddiDialog title={t`Add new car`} onClose={() => setOpen(false)} open={isOpen}>
      <Typography>
        <Trans>Use this if you want to add more cars to an existing appointment</Trans>
      </Typography>
      {!selectedCar && (
        <LicensePlateSearch
          setCarSearchResult={setCarSearchResult}
          regNumber={regNumber}
          setRegNumber={setRegNumber}
          setIsSelectingCar={setIsSelectingCar}
          setShowCarSearchResult={setShowCarSearchResult}
        />
      )}

      {isSelectingCar &&
        (showCarSearchResult ? (
          <CarSearchResult
            carSearchResult={carSearchResult}
            regNumber={regNumber}
            onAddCar={() => {
              setShowCarSearchResult(false);
            }}
          />
        ) : (
          carSearchResult && (
            <SelectSalesItem
              licenseAreaId={licenseAreaId}
              carSearchResult={carSearchResult}
              setSelectedCar={setSelectedCar}
              setSelectedSalesItems={setSelectedSalesItems}
              selectedSalesItems={selectedSalesItems}
              fetchForNewCar={true}
              onAddService={() => {
                setIsSelectingCar(false);
              }}
            />
          )
        ))}

      {!isSelectingCar && selectedCar && (
        <SelectedCars
          selectedCar={selectedCar}
          onRemoveCar={() => setSelectedCar(null)}
          selectedSalesItems={selectedSalesItems}
        />
      )}
      {error && <ApiErrorMessage error={error} />}

      <div className='mt-4 flex flex-col gap-4'>
        {!isSelectingCar && (
          <NoddiButton
            fullWidth
            disabled={!selectedCar}
            loading={isAddBookingItemPending}
            onClick={async () => {
              const req = {
                bookingId,
                car: selectedCar,
                salesItemIds: selectedSalesItems.map((salesItem) => salesItem.id)
              };
              await addBookingItemToExistingBooking(req);
            }}
          >
            <Typography variant='body1'>
              <Trans>Confirm new vehicle</Trans>
            </Typography>
          </NoddiButton>
        )}
      </div>
    </NoddiDialog>
  );
};

export default AddExtraCarModal;
