import { t } from '@lingui/macro';
import { Badge } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { CapacityContribution, CapacityDay } from 'noddi-async/src/types';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { DateFormats, format } from 'noddi-util';

import { cn } from '../../../helpers/utilts';
import { NoddiDateCalendar } from '../../Elements/Inputs';

interface CustomBadgeContentProps {
  type: 'saved' | 'unsaved';
}

const CustomBadgeContent = ({ type }: CustomBadgeContentProps) => {
  return (
    <div
      className={cn('size-[7px] rounded-full', type === 'saved' ? ' bg-primary-purple' : ' bg-secondary-orange')}
    ></div>
  );
};

type RegisterDateCalendarProps = {
  capacityDays: CapacityDay[];
  capacityContributions: CapacityContribution[];
  allowMultipleContributionsOnSameDate?: boolean;
  daysToRegister: string[];
  setDaysToRegister: (dates: string[]) => void;
};

const RegisterDateCalendar = ({
  daysToRegister,
  capacityDays,
  capacityContributions,
  setDaysToRegister,
  allowMultipleContributionsOnSameDate
}: RegisterDateCalendarProps) => {
  const { noddiToast } = useNoddiToast();

  const handleDateChange = (date: Date | undefined) => {
    if (!date) {
      return;
    }

    const formattedDate = format(date, DateFormats.DASHED_DATE_ISO_8601);

    const isAlreadyRegistered = capacityContributions?.find(
      (day) => format(day.date, DateFormats.DASHED_DATE_ISO_8601) === formattedDate
    );

    const workerTyingToRegisterOnAlreadyRegisteredDate = !allowMultipleContributionsOnSameDate && isAlreadyRegistered;

    if (workerTyingToRegisterOnAlreadyRegisteredDate) {
      noddiToast.error(t`Cannot add date that already has existing availability. Contact your supervisor`);
      return;
    }

    const isDateAlreadyInUnsavedAvailabilityList = daysToRegister.find((d) => d === formattedDate);

    if (isDateAlreadyInUnsavedAvailabilityList) {
      noddiToast.error(t`Date already in list, remove existing date from list`);
      return;
    }

    setDaysToRegister(daysToRegister.concat(formattedDate));
  };

  return (
    <NoddiDateCalendar
      onChange={(date) => handleDateChange(date)}
      shouldDisableDate={(date) => {
        // should disable if we currently haven't created the respective capacity day yet
        const capacityDayHasBeenCreated = capacityDays?.find(
          (capacityDay) =>
            format(capacityDay.date, DateFormats.DASHED_DATE_ISO_8601) ===
            format(date, DateFormats.DASHED_DATE_ISO_8601)
        );

        return !capacityDayHasBeenCreated;
      }}
      sx={{
        '& .MuiPickersDay-root': {
          width: '42px',
          height: '36px'
        },
        '& .MuiDayCalendar-header': {
          '& .MuiDayCalendar-weekDayLabel': {
            width: '48px',
            height: '48px',
            fontSize: '1rem'
          }
        },
        '& .Mui-selected': {
          backgroundColor: 'transparent !important',
          color: 'inherit !important'
        }
      }}
      slots={{
        day: (props) => {
          const isSelected = daysToRegister.find(
            (date) => date === format(props.day, DateFormats.DASHED_DATE_ISO_8601)
          );

          const isAlreadyRegistered = capacityContributions?.find(
            (day) =>
              format(day.date, DateFormats.DASHED_DATE_ISO_8601) === format(props.day, DateFormats.DASHED_DATE_ISO_8601)
          );

          const badgeContentText =
            isAlreadyRegistered && !isSelected ? (
              <CustomBadgeContent type='saved' />
            ) : (
              isSelected && <CustomBadgeContent type='unsaved' />
            );

          return (
            <Badge
              badgeContent={badgeContentText}
              overlap='circular'
              sx={{
                '& .MuiBadge-badge': {
                  top: '85%',
                  right: '50%'
                }
              }}
            >
              <PickersDay {...props} outsideCurrentMonth={false} day={props.day} />
            </Badge>
          );
        }
      }}
    />
  );
};

export { RegisterDateCalendar };
