import { WorkItem } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui';
import { DateFormats, format, secondsToHours } from 'noddi-util';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const WorkCard = ({ workInfo }: { workInfo: WorkItem }) => {
  return (
    <div className='flex flex-col gap-2 rounded-xl bg-primary-white px-4 py-3'>
      <h5 className='font-bold'>
        {capitalizeFirstLetter(format(new Date(workInfo.date), DateFormats.FULL_DAY_MONTH))}
      </h5>
      <p className='flex items-center gap-2'>
        <div>
          <NoddiIcon name='LocationPin' size='medium' />
        </div>
        {workInfo.startAddress.streetName} {workInfo.startAddress.streetNumber} - {workInfo.endAddress.streetName}{' '}
        {workInfo.endAddress.streetNumber}
      </p>

      <p className='flex items-center gap-2'>
        <div>
          <NoddiIcon name='ClockCircle' size='medium' />
        </div>
        {format(workInfo.startTime, DateFormats.TIME)} - {format(workInfo.endTime, DateFormats.TIME)}
        {', '}
        Max {secondsToHours(workInfo.maxDurationInSeconds)} h
      </p>
    </div>
  );
};

export default WorkCard;
