import { Trans, t } from '@lingui/macro';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { CarTireMeasurements } from 'noddi-async/src/types';
import { NoddiAsyncError, NoddiButton, NoddiFormTextInput, NoddiRadioButton } from 'noddi-ui';
import { useState } from 'react';

import { getCarTireMeasurementsSchema } from './validation';

const initialValues = {
  summer: {
    frontLeft: null,
    frontRight: null,
    rearLeft: null,
    rearRight: null
  },
  winter: {
    frontLeft: null,
    frontRight: null,
    rearLeft: null,
    rearRight: null
  }
} as const;

type InitialMeasurementValues = typeof initialValues;

interface MeasureTiresProps {
  carId: number;
  isAddTireMeasurementsPending: boolean;
  addTireMeasurements: UseMutateAsyncFunction<
    undefined,
    NoddiAsyncError,
    {
      carId: number;
      measurements: CarTireMeasurements;
    },
    unknown
  >;
  ctaText: string;
}

const MeasureTiresForm = ({ addTireMeasurements, carId, isAddTireMeasurementsPending, ctaText }: MeasureTiresProps) => {
  const [isSummer, setIsSummer] = useState(true);

  // Custom change handler to replace commas with dots
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    const { name, value } = event.target;

    const normalizedValue = value.replace(',', '.');
    setFieldValue(name, normalizedValue);
  };

  return (
    <>
      <Formik<InitialMeasurementValues>
        initialValues={initialValues}
        validateOnMount
        validationSchema={getCarTireMeasurementsSchema()}
        onSubmit={() => {}}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            <div className='mt-6 grid grid-cols-2 gap-4'>
              {isSummer ? (
                <>
                  <NoddiFormTextInput
                    name='summer.frontLeft'
                    label={t`Front left (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='summer.frontRight'
                    label={t`Front right (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='summer.rearLeft'
                    label={t`Rear left (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='summer.rearRight'
                    label={t`Rear right (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                </>
              ) : (
                <>
                  <NoddiFormTextInput
                    name='winter.frontLeft'
                    label={t`Front left (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='winter.frontRight'
                    label={t`Front right (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='winter.rearLeft'
                    label={t`Rear left (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                  <NoddiFormTextInput
                    name='winter.rearRight'
                    label={t`Rear right (mm)`}
                    type='text'
                    inputProps={{ inputMode: 'decimal' }}
                    onChange={(event) => handleInputChange(event, setFieldValue)}
                  />
                </>
              )}
            </div>
            <div className='my-6 flex justify-center gap-4'>
              <NoddiRadioButton checked={isSummer} onChange={() => setIsSummer(true)} label={t`Summer`} />
              <NoddiRadioButton checked={!isSummer} onChange={() => setIsSummer(false)} label={t`Winter`} />
            </div>
            {!isValid && (
              <p>
                <Trans>Please fill in values for both winter and summer tires</Trans>
              </p>
            )}
            <NoddiButton
              className='mt-1'
              fullWidth
              variant='success'
              disabled={!isValid}
              loading={isAddTireMeasurementsPending}
              onClick={async () => {
                await addTireMeasurements({
                  carId,
                  //@ts-ignore
                  measurements: values
                });
              }}
            >
              {ctaText}
            </NoddiButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MeasureTiresForm;
