import { Trans, plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { useNoddiToast } from 'noddi-provider/src/utils';
import {
  ApiErrorMessage,
  IconName,
  NoddiButton,
  NoddiCircularLoader,
  NoddiCollapseCard,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader,
  colors
} from 'noddi-ui';
import { DateFormats, format, fromSecondsToMinutes, intervalToDuration } from 'noddi-util';
import { useState } from 'react';

import { openAddressInGoogleMaps } from '../../utils/navigation';
import { getIncreasedArrivalTime, getReducedArrivalTime, roundToNearestMinuteInterval } from './utils';

interface IconRowProps {
  iconName: IconName;
  value: string;
  onClick?: () => void;
  className?: string;
}

const InfoRow = ({ iconName, value, onClick, className }: IconRowProps) => (
  <div className='mt-1 flex items-center gap-2' onClick={onClick}>
    <div>
      <NoddiIcon name={iconName} />
    </div>
    <p className={className}>{value}</p>
  </div>
);

interface BookingHeaderInfo {
  routeItem: RouteItemForServiceWorker;
}

const BookingHeaderInfo = ({ routeItem }: BookingHeaderInfo) => {
  const estimatedDrivingTimeInMinutes = fromSecondsToMinutes(routeItem.estimatedDrivingTimeInSeconds);
  const { noddiToast } = useNoddiToast();
  const [nextArrivalTime, setNextArrivalTime] = useState(roundToNearestMinuteInterval(estimatedDrivingTimeInMinutes));
  const [translatedUserComment, setTranslatedUserComment] = useState<string | null>(null);
  const {
    data: routeItemWithCustomerOverview,
    isPending: isRouteItemPending,
    error: routeItemError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteItemCustomerOverview,
    input: { routeItemId: routeItem.id }
  });

  const { mutateAsync: translateUserComment, isPending: isTranslateUserCommentPending } = noddiAsync.usePost({
    type: URLKeys.postTranslateText,
    queryConfig: {
      onSuccess: (data) => {
        setTranslatedUserComment(data.data.translatedText);
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { mutateAsync: sendNextArrivalSMS, isPending: isSendNextArrivalSMSPending } = noddiAsync.usePost({
    type: URLKeys.postNextCustomerArrivalSMS,
    queryConfig: {
      onSuccess: () => {
        noddiToast.success(t`SMS sent`);
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  if (isRouteItemPending) {
    return <NoddiCircularLoader />;
  }

  if (routeItemError) {
    return <ApiErrorMessage error={routeItemError} />;
  }

  const {
    address,
    estimatedArrival,
    estimatedDeparture,
    customerComments,
    serviceTimeInSeconds,
    customerPhoneNumber,
    adminComments,
    customerName,
    bookingItems
  } = routeItemWithCustomerOverview;

  const numberOfCars = bookingItems.length;

  const numberOfCarsText = plural(numberOfCars, {
    one: `# car`,
    other: `# cars`
  });

  const expectedArrival = `${format(estimatedArrival, DateFormats.TIME)} - ${format(estimatedDeparture, DateFormats.TIME)}`;
  const nextArrivalTimeText = t`Customer will get SMS that you will be arriving in ${nextArrivalTime} minutes`;

  const serviceTime = intervalToDuration({ start: 0, end: serviceTimeInSeconds * 1000 });

  const totalRouteTime = serviceTime.hours
    ? `${serviceTime.hours} hours ${serviceTime.minutes} min`
    : `${serviceTime.minutes} min`;

  return (
    <div>
      <p className='text-6'>
        {address.streetName} {address.streetNumber}
      </p>
      <NoddiButton variant='link' className='!pl-0' onClick={() => openAddressInGoogleMaps(address)}>
        <Trans> Open in google maps</Trans>
      </NoddiButton>

      <InfoRow iconName='UserCircle' value={customerName} />
      <InfoRow
        iconName='Phone'
        onClick={() => window.open('tel:' + customerPhoneNumber)}
        value={customerPhoneNumber}
        className='cursor-pointer underline'
      />

      <InfoRow iconName='ClockCircle' value={expectedArrival} />
      {routeItem.startedAt ? (
        <InfoRow iconName='Car' value={`${numberOfCarsText}, ${totalRouteTime}`} />
      ) : (
        <div className='mt-1 flex flex-col gap-2'>
          {bookingItems.map(({ car, orderLineDescriptions }) => (
            <div key={car.licensePlateNumber} className='flex flex-col'>
              <div className='flex items-center gap-2'>
                <div>
                  <NoddiIcon name='Car' />
                </div>
                <p className='font-semibold'>
                  {car.make} - {car.licensePlateNumber}
                </p>
              </div>
              {orderLineDescriptions.map((description) => (
                <div className='ml-8 flex flex-col'>
                  <p key={description} className='text-3'>
                    {description}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {adminComments && (
        <div className='mt-1 flex gap-2'>
          <div>
            <NoddiIcon name='ChatRounded' />
          </div>
          <p>
            <Trans>Admin comment</Trans>: {adminComments}
          </p>
        </div>
      )}
      {customerComments && (
        <div className='mt-1'>
          {isTranslateUserCommentPending ? (
            <NoddiLinearProgressLoader />
          ) : (
            <div className='flex gap-2'>
              <div>
                <NoddiIcon name='ChatRounded' />
              </div>

              <p>{translatedUserComment ?? customerComments}</p>
            </div>
          )}
          {translatedUserComment ? (
            <NoddiButton
              variant='link'
              className='pl-0'
              startIcon='AltArrowUp'
              onClick={() => setTranslatedUserComment(null)}
            >
              <Trans>See original comment</Trans>
            </NoddiButton>
          ) : (
            <NoddiButton
              variant='link'
              className='pl-0'
              startIcon='Globe'
              onClick={async () =>
                await translateUserComment({
                  text: customerComments,
                  targetLanguage: 'en'
                })
              }
            >
              <Trans> Translate user comment</Trans>
            </NoddiButton>
          )}
        </div>
      )}

      {!routeItem.startedAt && (
        <div className='mt-4'>
          <NoddiCollapseCard
            backgroundColor={colors.secondary.coral70}
            preventHandleExpand
            sx={{ py: 2 }}
            header={
              <p className='font-semibold'>
                <Trans>Send notification</Trans>
              </p>
            }
            collapseBody={
              <div className='mt-4'>
                <p className='text-3'>{nextArrivalTimeText}</p>
                <div className='mt-4 flex justify-between'>
                  <div className='flex items-center justify-center gap-4'>
                    <NoddiIconButton
                      iconName='Minus'
                      variant='secondary'
                      iconSize='small'
                      onClick={() => {
                        const newValue = getReducedArrivalTime(nextArrivalTime);
                        if (newValue) {
                          setNextArrivalTime(newValue);
                        }
                      }}
                    />
                    <p>{nextArrivalTime} min</p>
                    <NoddiIconButton
                      iconName='Plus'
                      variant='secondary'
                      iconSize='small'
                      onClick={() => {
                        const newValue = getIncreasedArrivalTime(nextArrivalTime);
                        if (newValue) {
                          setNextArrivalTime(newValue);
                        }
                      }}
                    />
                  </div>
                  <NoddiButton
                    variant='secondary'
                    size='small'
                    loading={isSendNextArrivalSMSPending}
                    onClick={async () =>
                      await sendNextArrivalSMS({ routeItemId: routeItem.id, numMinutes: nextArrivalTime })
                    }
                  >
                    <Trans>Send</Trans>
                  </NoddiButton>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

export default BookingHeaderInfo;
