import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { NoddiButton, NoddiIconButton, NoddiLinearProgressLoader } from 'noddi-ui';
import { fromSecondsToMinutes } from 'noddi-util';
import { useState } from 'react';

import { openAddressInGoogleMaps } from '../../utils/navigation';
import { getIncreasedArrivalTime, getReducedArrivalTime, roundToNearestMinuteInterval } from './utils';

interface BookingCompletedProps {
  nextRouteItemAfterCurrent: RouteItemForServiceWorker;
  routeId: number;
  routeItemId: number;
  bookingId: number;
  onNavigateOnComplete?: () => void;
}

const BookingCompleted = ({
  nextRouteItemAfterCurrent,
  routeId,
  routeItemId,
  bookingId,
  onNavigateOnComplete
}: BookingCompletedProps) => {
  const estimatedDrivingTimeInMinutes = fromSecondsToMinutes(nextRouteItemAfterCurrent.estimatedDrivingTimeInSeconds);
  const { noddiToast } = useNoddiToast();
  const [nextArrivalTime, setNextArrivalTime] = useState(roundToNearestMinuteInterval(estimatedDrivingTimeInMinutes));

  const { mutateAsync: markBookingAsComplete, isPending: isMarkBookingAsCompletePending } = noddiAsync.usePost({
    type: URLKeys.postMarkBookingAsComplete,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteForServiceWorker,
          input: { routeId }
        });
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemCustomerOverview,
          input: { routeItemId }
        });
        onNavigateOnComplete?.();
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { mutateAsync: sendNextArrivalSMS, isPending: isSendNextArrivalSMSPending } = noddiAsync.usePost({
    type: URLKeys.postNextCustomerArrivalSMS,
    queryConfig: {
      onSuccess: async () => {
        await markBookingAsComplete({ bookingId });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const nextArrivalTimeText = t`Customer will get SMS that you will be arriving in ${nextArrivalTime} minutes`;

  return nextRouteItemAfterCurrent ? (
    <div>
      <div className='flex flex-col gap-4'>
        <NoddiButton
          startIcon='Globe'
          variant='link'
          className='!pl-0'
          onClick={() => openAddressInGoogleMaps(nextRouteItemAfterCurrent.address)}
        >
          <Trans> Open next address in google maps</Trans>
        </NoddiButton>

        <p className='text-center font-bold'>Estimated driving time</p>
        <div className='flex items-center justify-center gap-4'>
          <NoddiIconButton
            iconName='Minus'
            iconSize='small'
            onClick={() => {
              const newValue = getReducedArrivalTime(nextArrivalTime);
              if (newValue) {
                setNextArrivalTime(newValue);
              }
            }}
          />
          <p>{nextArrivalTime} min</p>
          <NoddiIconButton
            iconName='Plus'
            iconSize='small'
            onClick={() => {
              const newValue = getIncreasedArrivalTime(nextArrivalTime);
              if (newValue) {
                setNextArrivalTime(newValue);
              }
            }}
          />
        </div>
        <p className='mb-2 mt-8 text-3'>{nextArrivalTimeText}</p>
      </div>
      {isMarkBookingAsCompletePending ? (
        <NoddiLinearProgressLoader />
      ) : (
        <>
          <NoddiButton
            fullWidth
            endIcon='ArrowRight'
            loading={isSendNextArrivalSMSPending}
            onClick={async () => await sendNextArrivalSMS({ routeItemId, numMinutes: nextArrivalTime })}
          >
            <Trans>Send SMS and go to customer</Trans>
          </NoddiButton>
          <div className='flex justify-center'>
            <NoddiButton variant='link' onClick={async () => await markBookingAsComplete({ bookingId })}>
              <Trans>Continue without sending SMS</Trans>
            </NoddiButton>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default BookingCompleted;
