import { plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { ApiErrorMessage, NoddiButton, NoddiLinearProgressLoader, NoddiSelectableBox } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

interface AddExtraServiceToBookingItemProps {
  bookingItemId: number;
  routeItemId: number;
  salesItemsOnBookingItem: { name: string; id: number }[];
}

const AddExtraServiceToBookingItem = ({
  bookingItemId,
  routeItemId,
  salesItemsOnBookingItem
}: AddExtraServiceToBookingItemProps) => {
  const [seeAllSalesItems, setSeeAllSalesItems] = useState(false);
  const [selectedSalesItemsIds, setSelectedSalesItemsIds] = useState<number[]>([]);
  const {
    data: availableSalesItems,
    isPending: isAvailableSalesItemsPending,
    error: availableSalesItemsError
  } = noddiAsync.useGet({
    type: URLKeys.getAvailableSalesItemsForBookingItem,
    input: { bookingItemId, type: seeAllSalesItems ? null : 'extra' }
  });

  const {
    mutateAsync: addSalesItemsToBooking,
    isPending: isAddingSalesItemsToBookingPending,
    error: addSalesItemsToBookingError
  } = noddiAsync.usePost({
    type: URLKeys.postAddExtraSalesItemsToBookingItem,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: { routeItemId }
        });
      }
    }
  });

  if (isAvailableSalesItemsPending) {
    return <NoddiLinearProgressLoader />;
  }

  if (availableSalesItemsError) {
    return <ApiErrorMessage error={availableSalesItemsError} />;
  }

  const numberOfSalesItemsToAdd = selectedSalesItemsIds.length;

  const ctaText = plural(numberOfSalesItemsToAdd, {
    one: 'Add extra task (#)',
    other: 'Add extra tasks (#)'
  });

  const seeMoreServicesText = seeAllSalesItems ? t`Show less tasks` : t`Show more tasks`;

  const filteredAvailableSalesItems = availableSalesItems.filter(
    ({ id }) => !salesItemsOnBookingItem.some((salesItem) => salesItem.id === id)
  );

  return (
    <div>
      {filteredAvailableSalesItems.map(({ id, name, price }) => {
        const isSelected = selectedSalesItemsIds.some((selectedSalesItemId) => selectedSalesItemId === id);

        return (
          <div className='mt-3' key={id}>
            <NoddiSelectableBox
              isSelected={isSelected}
              setIsSelected={() =>
                setSelectedSalesItemsIds((prevSelectedSalesItems) => {
                  if (isSelected) {
                    return prevSelectedSalesItems.filter((selectedSalesItemId) => selectedSalesItemId !== id);
                  }
                  return [...prevSelectedSalesItems, id];
                })
              }
            >
              <div className='flex items-start'>
                <p className='text-left'>
                  {name} - {formatCurrencyAmount(price, 0)}
                </p>
              </div>
            </NoddiSelectableBox>
          </div>
        );
      })}
      <NoddiButton
        endIcon={seeAllSalesItems ? 'AltArrowUp' : 'AltArrowDown'}
        variant='link'
        className='pl-0'
        onClick={() => setSeeAllSalesItems((prev) => !prev)}
      >
        {seeMoreServicesText}
      </NoddiButton>
      {addSalesItemsToBookingError && <ApiErrorMessage error={addSalesItemsToBookingError} />}
      <NoddiButton
        onClick={async () => {
          await addSalesItemsToBooking({ id: bookingItemId, salesItemIds: selectedSalesItemsIds });
        }}
        variant='primary'
        fullWidth
        loading={isAddingSalesItemsToBookingPending}
        disabled={numberOfSalesItemsToAdd === 0}
        className='mt-4'
      >
        {ctaText}
      </NoddiButton>
    </div>
  );
};

export default AddExtraServiceToBookingItem;
