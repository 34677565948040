import { secondsToMinutes } from 'date-fns';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';

export function getCurrentRouteItem(routeItems: RouteItemForServiceWorker[]) {
  return routeItems.find((routeItem) => {
    return !routeItem.completedAt && !routeItem.cancelledAt;
  });
}

// get next route item after upcoming route item
export function getNextRouteItem(
  routeItems: RouteItemForServiceWorker[],
  upcomingRouteItem: RouteItemForServiceWorker | undefined
) {
  if (!upcomingRouteItem) {
    return null;
  }
  const upcomingRouteItemIndex = routeItems.findIndex((routeItem) => routeItem.id === upcomingRouteItem.id);

  // make sure there is a next route item after upcoming route item
  if (upcomingRouteItemIndex === routeItems.length - 1) {
    return null;
  }
  return routeItems[upcomingRouteItemIndex + 1];
}

export function getFinishedRouteItems(routeItems: RouteItemForServiceWorker[]) {
  return routeItems.filter((routeItem) => {
    return routeItem.completedAt || routeItem.cancelledAt;
  });
}

export function isRouteWithinTimeThreshold(delayInSeconds: number) {
  const convertedToMinues = secondsToMinutes(delayInSeconds);
  return Math.abs(convertedToMinues) <= 30;
}
