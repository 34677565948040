import Stack from '@mui/material/Stack';
import { SalesCar } from 'noddi-async/src/types';
import { NoddiIcon, NoddiIconButton, colors } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { SalesItemDto } from '../../../hooks/apiHooks/useGetSalesItems';

type Props = {
  onRemoveCar: (value: string) => void;
  selectedCar: SalesCar;
  selectedSalesItems: SalesItemDto[];
};

const SelectedCars = ({ selectedCar, onRemoveCar, selectedSalesItems }: Props) => {
  return (
    <Stack spacing={0} style={{ marginBottom: '1em' }}>
      <div key={selectedCar?.licensePlateNumber ?? ''} className='my-4 flex items-center justify-between'>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <NoddiIcon name='Check' color={colors.primary.orange} className='mb-1' />
          <span style={{ marginLeft: '7px', fontSize: '0.9rem' }}>
            {selectedCar.make} {selectedCar.model}
            {selectedCar.licensePlateNumber && ` - ${selectedCar.licensePlateNumber}`}
          </span>
        </div>
        <NoddiIconButton
          iconSize='small'
          variant='destructive'
          iconName='Cross'
          onClick={() => onRemoveCar(selectedCar?.licensePlateNumber ?? '')}
        />
      </div>
      <ul>
        {selectedSalesItems.map((salesItem) => (
          <div
            key={salesItem.id}
            className='mt-2 flex items-center justify-between rounded-lg border bg-systemColors-purpleBg p-4'
          >
            <p>{salesItem.name}</p>
            <p>{formatCurrencyAmount(salesItem.price)}</p>
          </div>
        ))}
      </ul>
    </Stack>
  );
};

export default SelectedCars;
