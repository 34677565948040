import { Trans, t } from '@lingui/macro';
import { RouteForServiceWorker } from 'noddi-async/src/types';
import { NoddiButton, NoddiChip, NoddiDialog } from 'noddi-ui';
import { secondsToMinutes } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import RouteDetails from '../../components/ui/RouteCard/RouteDetails';
import { isRouteWithinTimeThreshold } from '../../utils/routeItem';

interface RouteHeaderProps {
  route: RouteForServiceWorker;
  routeItemId: number;
}
const RouteHeader = ({ route, routeItemId }: RouteHeaderProps) => {
  const navigate = useNavigate();
  // we add 1 to the number to adjust for 0-based index
  const routeItemIndex = route.routeItems.findIndex((item) => item.id === routeItemId) + 1;
  const numberOfRouteItems = route.routeItems.length;
  const customerOverviewStatusText = t`Customer ${routeItemIndex} out of ${numberOfRouteItems}`;

  const [isRouteOverviewOpen, setIsRouteOverviewOpen] = useState(false);

  const isDelayed = route.delayInSeconds > 0;

  const delayInMinutes = secondsToMinutes(route.delayInSeconds);
  const isDelayWithinThreshold = isRouteWithinTimeThreshold(route.delayInSeconds);

  const delayInMinutesAbs = Math.abs(delayInMinutes);

  const textString = isDelayed ? t`${delayInMinutesAbs} min behind` : t`${delayInMinutesAbs} min early `;
  const isRightOnTime = delayInMinutes === 0;

  return (
    <>
      <div className='my-4'>
        <div className={`mb-3 flex items-center ${isRightOnTime ? 'justify-end' : 'justify-between'}`}>
          {!isRightOnTime && <NoddiChip label={textString} variant={isDelayWithinThreshold ? 'success' : 'warning'} />}
          <NoddiButton variant='secondary' onClick={() => setIsRouteOverviewOpen((prev) => !prev)}>
            <Trans>Show route overview</Trans>
          </NoddiButton>
        </div>
        <p className='font-bold text-4 text-primary-darkPurple'>{customerOverviewStatusText}</p>
      </div>
      <NoddiDialog
        fullWidth
        onClose={() => setIsRouteOverviewOpen(false)}
        open={isRouteOverviewOpen}
        title={t`Route details`}
      >
        <RouteDetails
          routeItems={route.routeItems}
          onNavigateToRouteItem={(routeItemId) => {
            setIsRouteOverviewOpen(false);
            navigate(routes.routeItemOnRoute.getPath({ routeId: route.id, routeItemId }));
          }}
        />
      </NoddiDialog>
    </>
  );
};

export default RouteHeader;
