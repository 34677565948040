import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { useState } from 'react';

import BookingCompleted from './BookingCompleted';

interface CurrentRouteItemActionButtonsProps {
  currentRouteItem: RouteItemForServiceWorker;
  nextRouteItemAfterCurrent?: RouteItemForServiceWorker | null;
  routeId: number;
  onNavigateOnComplete?: () => void;
}

const CurrentRouteItemActionButtons = ({
  currentRouteItem,
  routeId,
  nextRouteItemAfterCurrent,
  onNavigateOnComplete
}: CurrentRouteItemActionButtonsProps) => {
  const { noddiToast } = useNoddiToast();
  const [isNextCustomerModalOpen, setIsNextCustomerModalOpen] = useState(false);

  const {
    data: activeBookingItems,
    isPending: isActiveBookingItemsPending,
    error: activeBookingItemsError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingItemsForRoute,
    input: { routeItemId: currentRouteItem.id }
  });

  const { mutateAsync: startJob, isPending: isStartJobPending } = noddiAsync.usePost({
    type: URLKeys.postMarkRouteItemStarted,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteForServiceWorker,
          input: { routeId }
        });
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { mutateAsync: markBookingAsComplete, isPending: isMarkBookingAsCompletePending } = noddiAsync.usePost({
    type: URLKeys.postMarkBookingAsComplete,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteForServiceWorker,
          input: { routeId }
        });
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getRouteItemCustomerOverview,
          input: { routeItemId: currentRouteItem.id }
        });
        onNavigateOnComplete?.();
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  if (isActiveBookingItemsPending || activeBookingItemsError) {
    return null;
  }

  const shouldDisableFinishAppointmentButton = activeBookingItems.some(
    (bookingItem) => !bookingItem.completedAt && !bookingItem.isAllOrderLinesUnableToComplete
  );

  const showCompleteButton = !currentRouteItem.completedAt && currentRouteItem.startedAt;

  return (
    <div className='mt-4'>
      {!currentRouteItem.startedAt && (
        <NoddiButton
          endIcon='ArrowRight'
          fullWidth
          loading={isStartJobPending}
          onClick={async () => {
            await startJob({
              routeId,
              routeItemId: currentRouteItem.id
            });
          }}
        >
          <Trans>I have arrived</Trans>
        </NoddiButton>
      )}
      {showCompleteButton && (
        <>
          {shouldDisableFinishAppointmentButton && (
            <p className='mb-1 text-3'>
              <Trans>You need to finish all cars in order to complete</Trans>
            </p>
          )}
          <NoddiButton
            variant='success'
            endIcon='ArrowRight'
            loading={isMarkBookingAsCompletePending}
            disabled={shouldDisableFinishAppointmentButton}
            onClick={async () => {
              if (nextRouteItemAfterCurrent) {
                setIsNextCustomerModalOpen(true);
              } else {
                await markBookingAsComplete({ bookingId: currentRouteItem.bookingId });
              }
            }}
            fullWidth
          >
            <Trans>Complete customer</Trans>
          </NoddiButton>
        </>
      )}
      {nextRouteItemAfterCurrent && (
        <NoddiDialog
          title={t`Notify next customer?`}
          open={isNextCustomerModalOpen}
          onClose={() => setIsNextCustomerModalOpen(false)}
        >
          <BookingCompleted
            onNavigateOnComplete={onNavigateOnComplete}
            nextRouteItemAfterCurrent={nextRouteItemAfterCurrent}
            routeId={routeId}
            routeItemId={currentRouteItem.id}
            bookingId={currentRouteItem.bookingId}
          />
        </NoddiDialog>
      )}
    </div>
  );
};

export default CurrentRouteItemActionButtons;
