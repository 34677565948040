import { t } from '@lingui/macro';
import { useState } from 'react';

import { NoddiButton, NoddiFormAddressPicker, NoddiFormSelect } from '../../Elements';

interface Props {
  addressesOptions: { label: string; value: number | undefined }[];
  defaultAddress: number | string;
}

export function CapacityContributionAddressess({ addressesOptions, defaultAddress }: Props) {
  const [isCustomStartAddress, setIsCustomStartAddress] = useState(false);
  const [isCustomEndAddress, setIsCustomEndAddress] = useState(false);

  return (
    <div className=' flex flex-col gap-2'>
      <div>
        {isCustomStartAddress ? (
          <NoddiFormAddressPicker name='startAddress' label={t`Enter start address`} addresses={[]} />
        ) : (
          <NoddiFormSelect
            name='startAddress'
            label={t`Select start address`}
            options={addressesOptions}
            value={defaultAddress}
            fullWidth
          />
        )}
        <NoddiButton onClick={() => setIsCustomStartAddress((prev) => !prev)} className='pl-0' variant='link'>
          {isCustomStartAddress ? t`Choose from existing address?` : t`Choose new start address?`}
        </NoddiButton>
      </div>

      <div>
        {isCustomEndAddress ? (
          <NoddiFormAddressPicker name='endAddress' label={t`Enter end address`} addresses={[]} />
        ) : (
          <NoddiFormSelect
            name='endAddress'
            label={t`Select end address`}
            options={addressesOptions}
            value={defaultAddress}
            fullWidth
          />
        )}
        <NoddiButton onClick={() => setIsCustomEndAddress((prev) => !prev)} className='pl-0' variant='link'>
          {isCustomEndAddress ? t`Choose from existing address?` : t`Choose new end address?`}
        </NoddiButton>
      </div>
    </div>
  );
}
