import { t } from '@lingui/macro';
import { PickingList } from 'noddi-ui';

import HomeContentWrapper from '../../layouts/HomeContentWrapper';

export const PickingListWorkerApp = () => {
  return (
    <HomeContentWrapper title={t`Picking list`}>
      <PickingList />
    </HomeContentWrapper>
  );
};
